import { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { useAuth } from '../contexts/Auth'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useMutation } from 'react-query'
import {
    List,
    ListItem,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@material-ui/core'

export default function IntegrationServerPage() {
    // Function to get bearer token
    const { getFzAuthSvcToken } = useAuth()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3">Integration Server</Typography>
            </Grid>
            <Grid item xs={12}>
                <ViewInventoryCard getToken={getFzAuthSvcToken} />
            </Grid>
            <Grid item xs={12}>
                <ViewLeadsCard getToken={getFzAuthSvcToken} />
            </Grid>
            <Grid item xs={12}>
                <ViewLinksCard getToken={getFzAuthSvcToken} />
            </Grid>
        </Grid>
    )
}

function ViewInventoryCard({ getToken }) {
    // State
    const [cid, setCID] = useState('')
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: viewInventory, isLoading: viewInventoryIsLoading } =
        useMutation(api.integrationServer.viewInventory, {
            onSuccess: (response) => {
                setResult(
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    'Found ' +
                                    response.length +
                                    ' vehicle(s) for CID ' +
                                    cid
                                }
                            />
                        </ListItem>
                        {response.map((el) => (
                            <VehicleCard key={el.FrazerID} vehicle={el} />
                        ))}
                    </List>
                )
            },
            onError: (err) => {
                // If we can't reach the integration server, we expect an error
                // object with a message field. If we can reach the integration
                // server, we expect an error string.
                setResult(
                    err.error?.message ||
                        err.error ||
                        `An unexpected error occurred: ${JSON.stringify(err)}`
                )
            }
        })

    // Form submit handler
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        viewInventory({
            token,
            cid
        })
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <fieldset disabled={viewInventoryIsLoading}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    View Inventory
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="CID"
                                    value={cid}
                                    onChange={(e) => {
                                        setCID(e.target.value)
                                    }}
                                    fullWidth
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Grid>
                            {result && (
                                <Grid item xs={12}>
                                    {result}
                                </Grid>
                            )}
                        </Grid>
                    </fieldset>
                </form>
            </CardContent>
        </Card>
    )
}
ViewInventoryCard.propTypes = {
    getToken: PropTypes.func.isRequired
}

// The data view is complicated enough to merit being broken out (it has state per displayed vehicle)
function VehicleCard({ vehicle }) {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText
                    primary={
                        vehicle.Data.Stock +
                        ' - ' +
                        vehicle.Data.Year +
                        ' ' +
                        vehicle.Data.Make +
                        ' ' +
                        vehicle.Data.Model +
                        ' (VehicleSysID: ' +
                        vehicle.FrazerID +
                        ')'
                    }
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open}>
                <Table size="small">
                    <TableBody>
                        {Object.keys(vehicle.Data).map((k) => (
                            <TableRow key={k}>
                                <TableCell>{k}</TableCell>
                                <TableCell>
                                    {JSON.stringify(vehicle.Data[k])}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse>
        </>
    )
}
VehicleCard.propTypes = {
    vehicle: PropTypes.shape({
        FrazerID: PropTypes.number.isRequired,
        Data: PropTypes.shape({
            Stock: PropTypes.string.isRequired,
            Year: PropTypes.number.isRequired,
            Make: PropTypes.string.isRequired,
            Model: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
}

function ViewLeadsCard({ getToken }) {
    // State
    const [cid, setCID] = useState('')
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: viewLeads, isLoading: viewLeadsIsLoading } =
        useMutation(api.integrationServer.viewLeads, {
            onSuccess: (response) => {
                setResult(
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={
                                    'Found ' +
                                    response.length +
                                    ' leads(s) for CID ' +
                                    cid
                                }
                            />
                        </ListItem>
                        {response.map((el) => (
                            <LeadCard
                                key={el.Partner + '-' + el.PartnerID}
                                lead={el}
                            />
                        ))}
                    </List>
                )
            },
            onError: (err) => {
                // If we can't reach the integration server, we expect an error
                // object with a message field. If we can reach the integration
                // server, we expect an error string.
                setResult(
                    err.error?.message ||
                        err.error ||
                        `An unexpected error occurred: ${JSON.stringify(err)}`
                )
            }
        })

    // Form submit handler
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        viewLeads({
            token,
            cid
        })
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <fieldset disabled={viewLeadsIsLoading}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">View Leads</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    label="CID"
                                    value={cid}
                                    onChange={(e) => {
                                        setCID(e.target.value)
                                    }}
                                    fullWidth
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Grid>
                            {result && (
                                <Grid item xs={12}>
                                    {result}
                                </Grid>
                            )}
                        </Grid>
                    </fieldset>
                </form>
            </CardContent>
        </Card>
    )
}
ViewLeadsCard.propTypes = {
    getToken: PropTypes.func.isRequired
}

// The data view is complicated enough to merit being broken out (it has state per displayed lead)
function LeadCard({ lead }) {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={lead.Partner + ' ' + lead.PartnerID} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open}>
                <Table size="small">
                    <TableBody>
                        {Object.keys(lead.Summary).map((k) => (
                            <TableRow key={k}>
                                <TableCell>{k}</TableCell>
                                <TableCell>
                                    {JSON.stringify(lead.Summary[k])}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse>
        </>
    )
}
LeadCard.propTypes = {
    lead: PropTypes.shape({
        Partner: PropTypes.string.isRequired,
        PartnerID: PropTypes.string.isRequired,
        Summary: PropTypes.shape({
            Buyer: PropTypes.shape({
                LastName: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    }).isRequired
}

function ViewLinksCard({ getToken }) {
    // State
    const [input, setInput] = useState('')
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: viewLinks, isLoading: viewLinksIsLoading } =
        useMutation(api.integrationServer.viewLinks, {
            onSuccess: (response) => {
                setResult(
                    <List dense={true}>
                        {response.Dealers && (
                            <ListItem key="LinkedDealersTotal">
                                <ListItemText
                                    primary={
                                        'There are ' +
                                        response.Dealers.length +
                                        ' linked dealer(s)'
                                    }
                                />
                            </ListItem>
                        )}
                        {response.Dealers &&
                            response.Dealers.map((el) => {
                                return (
                                    <ListItem key={el}>
                                        <ListItemText primary={el} />
                                    </ListItem>
                                )
                            })}
                        {response.Partners && (
                            <ListItem key="LinkedPartnersTotal">
                                <ListItemText
                                    primary={
                                        'There are ' +
                                        response.Partners.length +
                                        ' linked partner(s)'
                                    }
                                />
                            </ListItem>
                        )}
                        {response.Partners &&
                            response.Partners.map((el) => {
                                return (
                                    <ListItem key={el}>
                                        <ListItemText primary={el} />
                                    </ListItem>
                                )
                            })}
                    </List>
                )
            },
            onError: (err) => {
                // If we can't reach the integration server, we expect an error
                // object with a message field. If we can reach the integration
                // server, we expect an error string.
                setResult(
                    err.error?.message ||
                        err.error ||
                        `An unexpected error occurred: ${JSON.stringify(err)}`
                )
            }
        })

    // Form submit handler
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()

        let cid
        let code

        // Input is a CID if it's an integer.
        if (Number.isNaN(Number.parseInt(input))) {
            code = input
        } else {
            cid = input
        }

        viewLinks({
            token,
            cid,
            code
        })
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <fieldset disabled={viewLinksIsLoading}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    View Partner/Dealer Links
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="CID or Partner Code"
                                    value={input}
                                    onChange={(e) => {
                                        setInput(e.target.value)
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </Grid>
                            {result && (
                                <Grid item xs={12}>
                                    {result}
                                </Grid>
                            )}
                        </Grid>
                    </fieldset>
                </form>
            </CardContent>
        </Card>
    )
}
ViewLinksCard.propTypes = {
    getToken: PropTypes.func.isRequired
}
