import { useHistory } from 'react-router-dom'

import { Button } from '@material-ui/core'

const BackButton = () => {
    // Router History, allows us to navigate to other pages without using a Redirect component
    // https://v5.reactrouter.com/web/api/Hooks/usehistory
    let history = useHistory()
    return (
        <Button
            variant="contained"
            onClick={() => {
                history.goBack()
            }}
        >
            {'<- Go Back'}
        </Button>
    )
}

export default BackButton
