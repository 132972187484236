import PropTypes from 'prop-types'
import MUITable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'

export default function Table({ headers, rows, pagination }) {
    return (
        <TableContainer
            component={Paper}
            style={{ maxHeight: 600, overflow: 'auto' }}
        >
            <MUITable>
                <TableHead>
                    <TableRow>
                        {headers?.map((h) => (
                            <TableCell key={h}>{h}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row, i) => (
                        <TableRow key={i}>
                            {row.map((cell, ii) => (
                                <TableCell size="small" key={ii} scope="row">
                                    {cell}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                {pagination && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                count={pagination.count}
                                rowsPerPage={pagination.rowsPerPage}
                                onRowsPerPageChange={
                                    pagination.onRowsPerPageChange
                                }
                                page={pagination.page}
                                onPageChange={pagination.onPageChange}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </MUITable>
        </TableContainer>
    )
}

Table.propTypes = {
    headers: PropTypes.array,
    rows: PropTypes.array,
    pagination: PropTypes.shape({
        rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
        count: PropTypes.number,
        rowsPerPage: PropTypes.number,
        onRowsPerPageChange: PropTypes.func,
        page: PropTypes.number,
        onPageChange: PropTypes.func
    })
}
