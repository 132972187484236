import { createContext, useContext, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import {
    InteractionRequiredAuthError,
    InteractionStatus
} from '@azure/msal-browser'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useMutation } from 'react-query'

const AuthContext = createContext()

export const AuthProvider = (props) => {
    // State used to cache a Frazer auth service token.
    const [authSvcToken, setAuthSvcToken] = useState('')

    // API interactions (for Frazer auth service token fetching)
    const api = useAPIClient()
    const { mutateAsync: getAuthSvcToken } = useMutation(api.authSvc.getToken, {
        onSuccess: (response) => {
            setAuthSvcToken(response)
        },
        onError: (err) => {
            console.error(err)
            setAuthSvcToken('')
        }
    })

    // Hook to get information regarding whether user is logged in via MS OAuth.
    const { instance, accounts, inProgress } = useMsal()

    // Common scopes requested with MS OAuth.
    const scopes = ['user.read', 'Directory.Read.All']

    // Define the getToken function, which is also used in another call.
    const getToken = async () => {
        if (accounts.length <= 0) {
            // Not logged in, don't even attempt.
            return null
        }

        let response
        try {
            // Try to get a token silently.
            try {
                response = await instance.acquireTokenSilent({
                    scopes: scopes,
                    account: accounts[0],
                    forceRefresh: false
                })
            } catch (err) {
                // Failed to get a token silently.
                if (err instanceof InteractionRequiredAuthError) {
                    // Fallback to popup.
                    response = await instance.acquireTokenPopup({
                        scopes: scopes,
                        loginHint: accounts[0].username
                    })
                } else {
                    // Rethrow so commmon catch can handle it.
                    throw err
                }
            }
        } catch (err) {
            // Unavoidable error. Log but otherwise allow response to remain undefined.
            console.error(err)
        }

        return response?.accessToken
    }

    // Defining a separate func for this so I can hopefully get the calls to stop being ugly
    const getUserInfo = async () => {
        let t = await getToken()
        // Get job title
        let userInfo = await fetch(
            'https://graph.microsoft.com/v1.0/me?$select=companyName,jobTitle,userPrincipalName',
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${t}`
                }
            }
        ).then((u) => u.json())

        // request group info.
        let groupInfo = await fetch(
            'https://graph.microsoft.com/v1.0/me/memberOf/microsoft.graph.group?$select=displayName',
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${t}`,
                    consistencylevel: `eventual`
                }
            }
        ).then((g) => g.json())
        // Split on semicolon, trim all trailing / leading whitespace
        let authorizedGroups = process.env.REACT_APP_AUTHORIZED_GROUPS.split(
            ';'
        ).map((g) => g.trim())
        // Make sure they are a member of one of our Frazer groups
        let frazerEmployee = groupInfo.value.some(
            (v) =>
                v?.displayName !== undefined &&
                authorizedGroups.includes(v?.displayName)
        )

        // {companyName,jobTitle}
        return {
            jobTitle: userInfo.jobTitle,
            frazerEmployee: frazerEmployee,
            userPrincipalName: userInfo.userPrincipalName,
            msToken: t
        }
    }

    return (
        <AuthContext.Provider
            value={{
                login: async () => {
                    try {
                        await instance.loginPopup({
                            scopes: scopes
                        })
                    } catch (err) {
                        // User likely aborted login, locally log but otherwise ignore the error
                        console.log(err)
                    }
                },
                logout: async () => {
                    try {
                        await instance.logoutPopup({
                            account: accounts[0]
                        })
                    } catch (err) {
                        // User likely aborted logout, locally log but otherwise ignore the error
                        console.log(err)
                    }
                },
                loggedIn: accounts.length > 0,
                loginInProgress: inProgress === InteractionStatus.Login,
                loggedInUsername: accounts[0]?.username,
                getToken: getToken,
                getMSUserInfo: getUserInfo,
                getFzAuthSvcToken: async () => {
                    if (authSvcToken !== '') {
                        // These are valid for 12 hours, so we'll just assume
                        // it's still valid for now.
                        return authSvcToken
                    }

                    const accessToken = await getToken()
                    if (!accessToken) {
                        // No access token, don't even try to get a fz auth svc
                        // token.
                        return ''
                    }

                    return getAuthSvcToken({ accessToken }).finally(() => {
                        return authSvcToken
                    })
                }
            }}
            {...props}
        />
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
