// import React from 'react'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Define our Theme
// https://mui.com/customization/theming/
const TyrTheme = ({ children }) => {
    // Determine system preference on light/dark mode (This can be adjusted to be manual toggle as well)
    // https://mui.com/customization/dark-mode/
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

    // createTheme() returns a complete theme with defaults, we specify
    // what we want to overwrite by passing in argument object
    // Default theme: https://mui.com/customization/default-theme/
    const tyrTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        light: 'rgba(64, 43, 6, 1)',
                        main: 'rgba(34, 23, 3, 1)',
                        dark: 'rgba(20, 14, 2, 1)',
                        contrastText: '#fff'
                    },
                    secondary: {
                        light: 'rgba(234, 156, 5, 1)',
                        main: 'rgba(216, 144, 4, 1)',
                        dark: 'rgba(201, 134, 5, 1)',
                        contrastText: '#fff'
                    },
                    // NOTE: If you override the palette defaults for common, text, or background, MUI will assume
                    // you will handle this yourself with multiple themes and will not automatically adjust those colors.
                    mode: prefersDarkMode ? 'dark' : 'light'
                },
                typography: {
                    // By default MUI buttons transform button text UPPERCASE
                    button: {
                        textTransform: 'none'
                    },
                    // We loaded this resource in our public/index.html
                    fontFamily: ['Open Sans']
                },
                overrides: {
                    MuiTableCell: {
                        // Header Text in Table Cells in Header Rows should always be bold, however it is not by default.
                        // https://material-ui.com/api/table-cell/
                        head: {
                            fontWeight: 'bold'
                        }
                    }
                }
            }),
        [prefersDarkMode]
    )

    // Return our provider with our custom theme
    return <ThemeProvider theme={tyrTheme}>{children}</ThemeProvider>
}

export default TyrTheme

TyrTheme.propTypes = {
    children: PropTypes.element.isRequired
}
