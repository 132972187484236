import Grid from '@material-ui/core/Grid'
import SearchInput from '../components/Search'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

export default function SearchPage() {
    return (
        <Grid container>
            <Card>
                <CardContent>
                    <Grid item>
                        <SearchInput />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
