/**
 * Formatter helper function used by ForcedUpdateDetailPage and ForcuedUpdatesPage
 * @param {number} min
 * @param {number} max
 * @returns {string}
 */
export const formatVersions = (min, max) => {
    // If we don't have either, just return empty string
    if (min === 0 && max === 0) {
        return ''
    }
    // No minumum version, format accordingly
    if (min === 0) {
        return `≤ ${max}`
    }
    // We do have a minimum version, show the range
    return `${min} - ${max}`
}

/**
 * Formatter helper function used by ForcedUpdateDetailPage and ForcuedUpdatesPage
 * @param {Array} arr
 * @returns {string}
 */
export const formatArrays = (arr) => {
    // Do we even have any data to iterate through?
    if (!Array.isArray(arr)) {
        // Not an array
        return ''
    }
    if (arr.length === 0) {
        // Empty array
        return ''
    }
    // Iterate through the the array and build our string
    var s = ''
    for (var i = 0; i < arr.length; i++) {
        s = s + `${arr[i]}, ` // Append to our string
    }
    // Shave off the last ", " from the end of the string
    s = s.substring(0, s.length - 2)
    // Return the result
    return s
}

/**
 * Formatter helper function converts a date to MM/DD/YYYY format
 * @param {Date} d
 * @returns {string}
 */
export const formatDate = (d) => {
    // Do we even have a date?
    if (d === 0 || d === '' || d === undefined) {
        // No, just return empty string
        return ''
    }
    // This is the 0 date in go. also return empty string
    if (d === '0001-01-01T00:00:00Z') {
        return ''
    }
    // We should be able to convert the golang time into javascript time easily
    var jsDate = new Date(d)
    // Return formatted string of the date MM/DD/YYYY
    // return UTC values
    return `${
        jsDate.getUTCMonth() + 1
    }/${jsDate.getUTCDate()}/${jsDate.getUTCFullYear()}`
}

export const formatDateTime = (d) => {
    // Do we even have a date?
    if (d === 0 || d === '' || d === undefined) {
        // No, just return empty string
        return ''
    }
    // We should be able to convert the golang time into javascript time easily
    var jsDate = new Date(d)
    // Return desired format
    return jsDate.toLocaleString()
}

/** Ensures string ONLY contains numbers, commas, and whitespace
 * @param {string} s The string to sanitize
 * @returns {string} Sanitized string
 */
export const sanitizeStringOfCIDs = (s) => {
    // Sanitize it
    s = s.replace(/[^\d, ]/gim, '')
    // Return it
    return s
}

/**
 * Converts a string of CIDs ("2, 18292, 1234") to an array ([2, 18292, 1234])
 * @param {*} s The string to be converted into an array of numbers. Will be sanitized during conversion
 * @returns {Number[]}
 */
export const stringOfCIDsToArray = (s) => {
    // Ensure we're working with a string
    s = s + ''
    // First we'll sanitize the string of any unexpected characters (only numbers and commas allowed)
    s = s.replace(/[^\d,]/gim, '')
    // Next we'll break it out into an array of strings
    var stringArray = s.split(',')
    // Is the array we just created empty?
    if (stringArray.length === 0) {
        // Empty array, just return
        return []
    }
    // Iterate through the array, and convert it to a number, discard any entries that are NaN
    var outputArray = []
    for (var i = 0; i < stringArray.length; i++) {
        // Convert to Number
        var n = Number(stringArray[i])
        // Validate
        if (isNaN(n) || n === 0) {
            // Bad conversion, skip iteration
            continue
        }

        // Conversion successful, append it to our output array
        outputArray.push(n)
    }
    // Return the output array
    return outputArray
}

/**
 * Scrub a string for invalid characters.
 * Typical data entry from a user does not require and special characters (with the exception of passwords, which this function should not be used for)
 * @param {string} s The string to sanitize
 * @returns {string} Sanitized string.
 */
export const sanitize = (s) => {
    // Absolutely ensure we're working with a string
    s = s + '' // Adding an empty string to anything makes it a string

    // Scrub string of unwanted characters
    // '\.' is not a "useless-escape" in this regex
    // eslint-disable-next-line no-useless-escape
    s = s.replace(/[^a-zA-Z0-9 \.,_-]/gim, '')

    // Return the clean string
    return s
}

/**
 * sanitizeInt ensures a string only contains integers Example: "1.234" would be returned as "1234"
 * Intended to be used on a number field on change event to force desired input
 * @param {string} s The string to sanitize
 * @returns {string} The sanitized string that contains only integers
 */
export const sanitizeIntegers = (s) => {
    // Absolutely ensure we're working with a string
    s = s + '' // Adding an empty string to anything makes it a string
    // Strip any characters from the string that are not a number, and return it
    return s.replace(/[^\d]/gim, '')
}

// List of valid states
export const states = [
    'AK',
    'AL',
    'AR',
    'AZ',
    'CA',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VA',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY'
]
