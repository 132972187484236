import PropTypes from 'prop-types'
import { QueryClient, QueryClientProvider } from 'react-query'
import { APIClientProvider } from 'react-toolbox/APIClient'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import endpoints from '../api'
import TyrTheme from '../contexts/TyrTheme'
import { AuthProvider } from '../contexts/Auth'
import SidebarLayout from '../layouts/SidebarLayout'
import { BackupDetailProvider } from '../contexts/BackupDetail'

const WrappedEntry = ({ children }) => {
    if (process.env.NODE_ENV === 'development') {
        // Use mock service worker to fake network requests.
        require('../mocks/browser')
    }

    return (
        <TyrTheme>
            <QueryClientProvider client={new QueryClient()}>
                <APIClientProvider endpoints={endpoints}>
                    <MsalProvider
                        instance={
                            new PublicClientApplication({
                                auth: {
                                    clientId:
                                        process.env.REACT_APP_MS_CLIENT_ID,
                                    authority:
                                        process.env.REACT_APP_MS_TENANT_URL,
                                    redirectUri:
                                        process.env.REACT_APP_MS_REDIRECT_URI
                                }
                            })
                        }
                    >
                        <AuthProvider>
                            <BackupDetailProvider>
                                <SidebarLayout>{children}</SidebarLayout>
                            </BackupDetailProvider>
                        </AuthProvider>
                    </MsalProvider>
                </APIClientProvider>
            </QueryClientProvider>
        </TyrTheme>
    )
}

export default WrappedEntry

WrappedEntry.propTypes = {
    children: PropTypes.element.isRequired
}
