import Typography from '@material-ui/core/Typography'
import { Spinner } from '../components'
import Grid from '@material-ui/core/Grid'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useQuery, useMutation } from 'react-query'
import { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useAuth } from '../contexts/Auth'
import Paper from '@material-ui/core/Paper'

export default function AuthServicePage() {
    // Context state to get the auth token to authenticate API calls
    const { getFzAuthSvcToken, getToken } = useAuth()
    const [f4Token, setf4Token] = useState() // Microsoft token for F4.

    const api = useAPIClient()

    // State for textbox to allow user to enter CID to check
    const [cid, setCID] = useState('')
    // Message displayed to the user
    const [msg, setMsg] = useState({
        text: '',
        showButton: false,
        errColor: false
    })

    const obtainToken = async () => {
        let t = await getToken()
        setf4Token(t)
    }

    useEffect(() => {
        obtainToken()
    }, [])

    const [dealershipName, setDealershipName] = useState('')
    const [keyFound, setKeyFound] = useState()
    // If an error occurs fetching password system data, display a useful message to the user
    const [errorMsgF4, setErrorMsgF4] = useState('')

    // Set our message based on the results of both api requests
    useEffect(() => {
        // The request hasn't resolved yet, so don't display anything
        if (keyFound === undefined) {
            return
        }
        if (keyFound) {
            setMsg({
                text: `Public Key found on the AuthService for ${cid}`,
                showButton: true,
                errColor: false
            })
        } else {
            setMsg({
                text: `No Public Key found on the AuthService for ${cid}`,
                showButton: false,
                errColor: true
            })
        }
        // add our dealership name if we have it.
        if (dealershipName) {
            setMsg((prev) => ({
                ...prev,
                text: `${prev.text} - ${dealershipName}`
            }))
        }

        if (keyFound) {
            setMsg((prev) => ({
                ...prev,
                text: `${prev.text}, would you like to delete it ?`
            }))
        }
    }, [dealershipName, keyFound, cid])

    // Query to check a CID on the auth-service
    const { isLoading: checkIsLoading, refetch: checkCID } = useQuery(
        'checkCID',
        () => api.authSvc.checkCID({ cid, token: getFzAuthSvcToken() }),
        {
            retry: false,
            enabled: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            onError: (error) => {
                if (process.env.REACT_APP_STAGE !== 'PRODUCTION') {
                    console.log({ response: error })
                }
                if (error?.status === 404) {
                    setKeyFound(false)
                    setMsg({
                        text: `No Public Key found on the AuthService for ${cid}.`,
                        showButton: false,
                        errColor: true
                    })
                }
            },
            onSuccess: (data) => {
                if (process.env.REACT_APP_STAGE !== 'PRODUCTION') {
                    console.log({ response: data })
                }
                if (data) {
                    // Data detected, display message and return early
                    setKeyFound(true)
                    return
                }
                // Otherwise, set to false
                setKeyFound(false)
                setMsg({
                    text: `No Public Key found on the AuthService for ${cid}.`,
                    showButton: false,
                    errColor: true
                })
            }
        }
    )

    // get pws information so we can read the dealership name.
    const { isLoading: dealershipIsLoading, refetch: checkPWS } = useQuery(
        ['getDealershipInfo', cid],
        () => api.mimir.getDealershipInfo({ cid, token: f4Token }),
        {
            enabled: false,
            refetchOnWindowFocus: false,
            retry: 1,
            onSuccess: (f4Resp) => {
                setDealershipName(f4Resp?.data?.dealership?.name)
            },
            onError: (err) => {
                // Determine type of error
                if (err.error.kind === 'resource not found') {
                    // Not found
                    setErrorMsgF4(
                        `Password System data for CID ${cid} not found`
                    )
                    return
                }
                // Some other type of error occurred
                setErrorMsgF4(
                    `Encountered an error querying Password System data. Message: ${err.error.message}, Kind: ${err.error.kind}`
                )
            }
        }
    )

    // Handler for checkCID
    const handleCheckCID = () => {
        // Ensure we do not execute multiple API calls if the user mashes the button
        if (checkIsLoading || deleteIsLoading || dealershipIsLoading) {
            return
        }
        // Reset the found key and dealership state before query
        setKeyFound()
        setDealershipName()
        checkCID()
        checkPWS()
    }

    // Mutation
    const { mutate: deleteCID, isLoading: deleteIsLoading } = useMutation(
        api.authSvc.deleteCID,
        {
            onSuccess: () => {
                // Clear out CID from field
                let oldCID = cid
                setCID('')
                // Inform user oublic key was successfully deleted
                setMsg({
                    text: `Public Key for ${oldCID} deleted successfully.`,
                    showButton: false,
                    errColor: false
                })
            },
            onError: (error) => {
                // Inform user that there was a problem
                setMsg({
                    text: error?.message
                        ? error.message
                        : `Failed to delete Public Key for ${cid}. Please try again.`,
                    showButton: false,
                    errColor: true
                })
                // Normally we wouldn't console.log() an error, however this is an internal tool,
                // makes for easier debugging
                if (process.env.REACT_APP_STAGE !== 'PRODUCTION') {
                    console.log(error)
                }
            }
        }
    )
    // Handler for mutation
    const handleDeleteCID = async () => {
        let token = await getFzAuthSvcToken()

        // Ensure we're not already running API calls
        if (checkIsLoading || deleteIsLoading || dealershipIsLoading) {
            return
        }
        // Execute
        deleteCID({ cid, token })
    }

    // Helper function to check if an API call is able to be fired
    const isDisabled = () => {
        return (
            !!cid === false ||
            checkIsLoading ||
            deleteIsLoading ||
            dealershipIsLoading
        )
    }

    // TODO: Perhaps just move this to the search button, rather than making everything disappear
    // and only rendering a spinner
    if (checkIsLoading || deleteIsLoading) {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid item>
                    <Spinner />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid spacing={2} container>
            <Grid item xs={12}>
                <Paper elevation={3}>
                    <Grid container spacing={2} style={{ marginLeft: '16px' }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Manage Dealer Public Key
                            </Typography>
                        </Grid>
                        <Grid container alignItems="center" item xs={12}>
                            <Grid item xs={4}>
                                <TextField
                                    value={cid}
                                    onChange={(e) => {
                                        // Update value and reset message state
                                        setCID(e.target.value)
                                        setMsg({
                                            text: '',
                                            showButton: false,
                                            errColor: false
                                        })
                                        // reset state on change
                                        setErrorMsgF4()
                                        setKeyFound()
                                    }}
                                    onKeyDown={(e) => {
                                        // Fire the checkCID api call if enter is pressed
                                        if (e.key === 'Enter') {
                                            e.preventDefault()
                                            if (isDisabled()) {
                                                return
                                            }
                                            handleCheckCID()
                                        }
                                    }}
                                    variant="outlined"
                                    label="Enter CID"
                                    placeholder="18292"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    disabled={isDisabled()}
                                    variant="contained"
                                    onClick={() => handleCheckCID()}
                                    style={{ marginLeft: '16px' }}
                                >
                                    Check CID
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography
                                    color={msg.errColor ? 'error' : 'primary'}
                                >
                                    {msg.text}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {errorMsgF4 && (
                                    <Typography color={'error'}>
                                        {errorMsgF4}
                                    </Typography>
                                )}
                            </Grid>
                            {msg.showButton && (
                                <Grid item>
                                    <br />
                                    <Button
                                        disabled={
                                            !!cid === false ||
                                            checkIsLoading ||
                                            deleteIsLoading
                                        }
                                        variant="contained"
                                        onClick={() => handleDeleteCID()}
                                    >
                                        Delete Public Key for {cid}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}
