import { useEffect, useState, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { Table as CustomTable, Spinner, BackButton } from '../components'
import Grid from '@material-ui/core/Grid'
import { CheckIcon, CloseIcon } from '../components/Icons'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Link } from 'react-router-dom'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useQuery } from 'react-query'
import { useAuth } from '../contexts/Auth'
import { formatDateTime } from '../shared'
import { useTheme } from '@material-ui/core/styles'
import {
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination
} from '@material-ui/core'
import { BackupDetailContext } from '../contexts/BackupDetail'
import { useHistory } from 'react-router-dom'

export default function DealershipPage() {
    // Router History, allows us to navigate to other pages without using a Redirect component
    // https://v5.reactrouter.com/web/api/Hooks/usehistory
    let history = useHistory()
    // Get the theme as we'll need to use the error color to denote failed backups
    const theme = useTheme()
    // Get the CID from URL parameter
    let { cid } = useParams()
    // If an error occurs fetching password system data, display a useful message to the user
    const [errorMsgF4, setErrorMsgF4] = useState('')

    // If an error occurs fetching password system data, display a useful message to the user
    const [errorMsgBkp, setErrorMsgBkp] = useState('')

    // Pagination state
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // Pagination Functions used for our Table (passed in as part of the "pagination" prop)
    const onPageChange = (event, page) => {
        setPage(page)
    }
    const onRowsPerPageChange = (event) => {
        setRowsPerPage(event.target.value)
    }
    // If a user clicks on a backup in the table to view details for it, we need to set that data context
    const [, setBackupDetail] = useContext(BackupDetailContext)
    // Function to get bearer tokens (getToken is used for F4, and getFzAuthSvcToken is used for Monolith)
    const { getToken, getFzAuthSvcToken } = useAuth()
    const [token, setToken] = useState()
    const [authSvcToken, setAuthSvcToken] = useState()
    // Effect callbacks are synchronous to prevent race conditions. Put the async function inside
    const obtainToken = async () => {
        let t = await getToken()
        setToken(t)
    }
    const obtainAuthSvcToken = async () => {
        let t = await getFzAuthSvcToken()
        setAuthSvcToken(t)
    }
    // Get tokens on first mount
    useEffect(() => {
        obtainToken()
        obtainAuthSvcToken()
        // Empty dependency array allows this to run only once on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // API interaction
    const api = useAPIClient()
    // Query to get dealership info
    const { data: f4Resp, isLoading: dealershipIsLoading } = useQuery(
        ['getDealershipInfo', cid, token],
        () => api.mimir.getDealershipInfo({ cid, token }),
        {
            enabled: !!token,
            refetchOnWindowFocus: false,
            retry: 1,
            onError: (err) => {
                // Determine type of error
                if (err.error.kind === 'resource not found') {
                    // Not found
                    setErrorMsgF4(
                        `Password System data for CID ${cid} not found`
                    )
                    return
                }
                // Some other type of error occurred
                setErrorMsgF4(
                    `Encountered an error querying Password System data. Message: ${err.error.message}, Kind: ${err.error.kind}`
                )
            }
        }
    )
    // Query to get how many backups there are for this CID
    const { data: countResp, isLoading: countIsLoading } = useQuery(
        ['countBackups', cid, authSvcToken],
        () => api.mimir.countBackups({ cid, token: authSvcToken }),
        {
            retry: 1,
            enabled: !!authSvcToken,
            refetchOnWindowFocus: false,
            onError: (err) => {
                console.log(err)
                setErrorMsgBkp(
                    `Error querying for backup data: ${JSON.stringify(err)}`
                )
            }
        }
    )
    // Query to get backups
    const { data: buResp } = useQuery(
        ['listBackups', cid, authSvcToken, rowsPerPage, page],
        () =>
            api.mimir.listBackups({
                cid,
                token: authSvcToken,
                limit: rowsPerPage,
                page
            }),
        {
            retry: 1,
            enabled: !!authSvcToken,
            refetchOnWindowFocus: false,
            onError: (err) => {
                console.log(err)
                setErrorMsgBkp(
                    `Error querying for backup data: ${JSON.stringify(err)}`
                )
            }
        }
    )
    // Render spinner if query is still loading/fetching
    if (dealershipIsLoading || countIsLoading) {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid item>
                    <Spinner />
                </Grid>
            </Grid>
        )
    }

    // Formatter function for failed backups, needs to reside within this component as it requires a color from the theme
    const formatFailedBUField = (failed, val) => {
        // Is the backup unsuccessful?
        if (!failed) {
            // BU is good, no formatting necessary
            return val
        }
        // BU was a failure, return value in the error color
        return <span style={{ color: theme.palette.error.main }}>{val}</span>
    }

    return (
        <Grid spacing={2} container>
            <Grid container item xs={12}>
                {errorMsgF4 ? (
                    // Render not found if no CID provided or if unable to query
                    // PWS data for CID
                    <>
                        <Typography variant="h6" color="error">
                            {errorMsgF4}
                        </Typography>
                    </>
                ) : (
                    <Grid item>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">
                                    <b>
                                        {cid} - {f4Resp?.data?.dealership?.name}
                                    </b>
                                </Typography>
                                <Typography variant="body2">
                                    <b>
                                        {f4Resp?.data?.dealership?.address1}{' '}
                                        {f4Resp?.data?.dealership?.address2}
                                    </b>
                                    <br />
                                    <b>
                                        {f4Resp?.data?.dealership?.city},{' '}
                                        {f4Resp?.data?.dealership?.state}{' '}
                                        {f4Resp?.data?.dealership?.zip}
                                    </b>
                                    <br />
                                    <br />
                                    Last service contact:{' '}
                                    {formatDateTime(
                                        f4Resp?.data?.dealership
                                            ?.lastServiceContact
                                    )}
                                    <br />
                                    Last activity:{' '}
                                    {formatDateTime(
                                        f4Resp?.data?.dealership?.LastActivity
                                    )}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
                <Grid item style={{ flexGrow: 1 }} />
                <Grid item>
                    <BackButton />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {errorMsgF4 ? (
                    <></>
                ) : (
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Employees</Typography>
                            <CustomTable
                                headers={[
                                    'ID',
                                    'Name',
                                    'Email',
                                    'Enabled',
                                    'Admin'
                                ]}
                                rows={
                                    f4Resp?.data?.employees &&
                                    Array.isArray(f4Resp?.data.employees) &&
                                    f4Resp?.data.employees.map((e) => {
                                        return [
                                            e['id'],
                                            `${e['lastName']}, ${e['firstName']}`,
                                            <Link
                                                key={e['email']}
                                                to={`/user/${e['email']}`}
                                            >
                                                {e['email']}
                                            </Link>,
                                            e['disabled'] ? (
                                                <CloseIcon />
                                            ) : (
                                                <CheckIcon />
                                            ),
                                            e['admin'] ? (
                                                <CheckIcon />
                                            ) : (
                                                <CloseIcon />
                                            )
                                        ]
                                    })
                                }
                            />
                        </CardContent>
                    </Card>
                )}
            </Grid>

            {errorMsgF4 ? (
                <></>
            ) : (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Vehicles</Typography>
                            <CustomTable
                                headers={[
                                    'Stock #',
                                    'Year/Make/Model',
                                    'Created',
                                    'Modified',
                                    'P',
                                    '#',
                                    'Thumbnail',
                                    'VIN'
                                ]}
                                rows={f4Resp?.data?.vehicles.map((v) => [
                                    v['stock'],
                                    `${v['year']} ${v['make']} ${v['model']}`,
                                    v['dateCreated'],
                                    v['dateModified'],
                                    v['pending'] ? 'Y' : 'N',
                                    v['hasPhotos']
                                        ? `${v['photos'].length}`
                                        : '0',
                                    v['thumbnail'] ? (
                                        <img
                                            alt="thumbnail"
                                            key={`vehicles-${v['stock']}`}
                                            src={`${process.env.REACT_APP_FRAZER_PHOTOS_BASE_URL}/${cid}/${v['thumbnail']}`}
                                            style={{
                                                maxWidth: '48px',
                                                maxHeight: '48px'
                                            }}
                                        />
                                    ) : (
                                        ''
                                    ),
                                    v['vin']
                                ])}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )}
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Recent Backups</Typography>
                        {errorMsgBkp ? (
                            <Typography variant="h6" color="error">
                                Error querying backups
                            </Typography>
                        ) : (
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {[
                                                'Successful',
                                                'Date & Time',
                                                'Type',
                                                'User',
                                                'Path',
                                                'Host',
                                                'IP',
                                                'System',
                                                'Frazer',
                                                'Version'
                                            ].map((h) => (
                                                <TableCell key={h}>
                                                    {h}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {buResp?.backups.map((b, i) => {
                                            // Determine if this backup has an error or not
                                            var failed = false
                                            // Are we working with an array?
                                            if (Array.isArray(b['errors'])) {
                                                // We are, is it an empty array?
                                                if (b['errors'].length > 0) {
                                                    // Not an empty array, we have at least one error
                                                    failed = true
                                                }
                                            }
                                            return (
                                                <TableRow
                                                    key={i}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={async () => {
                                                        // Update our BackupDetail context
                                                        await setBackupDetail(b)
                                                        // Navigate to BackupDetail page
                                                        history.push(
                                                            `/dealership/${cid}/backup`
                                                        )
                                                    }}
                                                >
                                                    {[
                                                        failed ? (
                                                            <CloseIcon />
                                                        ) : (
                                                            <CheckIcon />
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            formatDateTime(
                                                                b['time']
                                                            )
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            `${b['auto_or_manual']} ${b['backup_type']}`
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['user']
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['install_path']
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['host']
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['host_ip']
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['system']
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['frazer_version']
                                                        ),
                                                        formatFailedBUField(
                                                            failed,
                                                            b['version']
                                                        )
                                                    ].map((cell, ii) => (
                                                        <TableCell
                                                            size="small"
                                                            key={ii}
                                                            scope="row"
                                                        >
                                                            {cell}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        <tr>
                                            {countResp?.count && (
                                                <TablePagination
                                                    count={countResp.count}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={
                                                        onRowsPerPageChange
                                                    }
                                                    page={page}
                                                    onPageChange={onPageChange}
                                                />
                                            )}
                                        </tr>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        )}
                    </CardContent>
                </Card>
            </Grid>
            {errorMsgF4 ? (
                <></>
            ) : (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Audits</Typography>
                            <CustomTable
                                headers={[
                                    'Date & Time',
                                    'CID',
                                    'User ID',
                                    'Admin',
                                    'Name',
                                    'Description'
                                ]}
                                rows={f4Resp?.data?.events.map((a) => {
                                    return [
                                        formatDateTime(a['dateCreated']),
                                        a['cid'],
                                        a['userID'],
                                        a['admin'] ? (
                                            <CheckIcon />
                                        ) : (
                                            <CloseIcon />
                                        ),
                                        a['name'],
                                        a['description']
                                    ]
                                })}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}
