import { createContext, useState } from 'react'

// This context exists solely to work around not being able to retrieve details from a single backup through
// an API call, so whenever someone clicks a specific backup to view details about that page, we update this
// context so the consumer (BackupsPage) can access the data

export const BackupDetailContext = createContext()

export const BackupDetailProvider = (props) => {
    const [backupDetail, setBackupDetail] = useState()
    return (
        <BackupDetailContext.Provider
            value={[backupDetail, setBackupDetail]}
            {...props}
        />
    )
}
