import MUICheckIcon from '@material-ui/icons/Check'
import MUICloseIcon from '@material-ui/icons/Close'

const CheckIcon = (props) => {
    return <MUICheckIcon style={{ color: 'green' }} {...props} />
}

const CloseIcon = (props) => {
    return <MUICloseIcon color="error" {...props} />
}

export { CheckIcon, CloseIcon }
