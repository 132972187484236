export const authsvc = (path) => {
    return new URL(path, process.env.REACT_APP_AUTH_SVC_BASE_URL).toString()
}

export const f4d = (path) => {
    return new URL(path, process.env.REACT_APP_F4D_BASE_URL).toString()
}

export const versionupdates = (path) => {
    // For Monolith services, using new URL() breaks local testing
    // Production monolith service URL would be https://versionupdates.frazercloud.com, which works fine
    // However local testing URL needs to be http://localhost:8080/versionupdates, but when parsed with new URL() it only returns http://localhost:8080
    return `${process.env.REACT_APP_VERSIONUPDATES_BASE_URL}${path}`
}

export const mimir = (path) => {
    // For Monolith services, using new URL() breaks local testing
    // Production monolith service URL would be https://backups.frazercloud.com, which works fine
    // However local testing URL needs to be http://localhost:8080/mimir, but when parsed with new URL() it only returns http://localhost:8080
    return `${process.env.REACT_APP_MIMIR_BASE_URL}${path}`
}

export const integrationserver = (path) => {
    return new URL(
        path,
        process.env.REACT_APP_INTEGRATION_SERVER_BASE_URL
    ).toString()
}

const endpoints = {
    authSvc: {
        getToken: ({ accessToken }) => ({
            path: authsvc('/api/v1/frazer/token'),
            method: 'GET',
            opts: {
                headers: { Authorization: `Bearer ${accessToken}` }
            }
        }),
        checkCID: ({ cid }) => ({
            path: authsvc(`/api/v1/dealers/${cid}/key`),
            method: 'GET'
        }),
        deleteCID: ({ cid, token }) => ({
            path: authsvc(`/api/v1/dealers/${cid}`),
            method: 'DELETE',
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        checkPermission: ({ token }) => ({
            path: authsvc(`/api/v1/metadata/permissions`),
            method: 'GET',
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        })
    },
    nps: {
        newAdmin: ({ cid, email, firstName, lastName, initials, token }) => ({
            path: f4d('/troubleshooting/new_admin'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid),
                email,
                firstName,
                lastName,
                initials
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        promoteToAdmin: ({ cid, email, token }) => ({
            path: f4d('/troubleshooting/promote_to_admin'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid),
                email
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        checkCache: ({ cid, token }) => ({
            // path: `${process.env.REACT_APP_F4D_BASE_URL}/troubleshooting/check_cache`,
            path: f4d('/troubleshooting/check_cache'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid)
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        clearCache: ({ cid, token }) => ({
            path: f4d('/troubleshooting/clear_cache'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid)
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        checkOnboarding: ({ cid, token }) => ({
            path: f4d('/troubleshooting/check_onboarding'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid)
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        clearOnboarding: ({ cid, confirm, token }) => ({
            path: f4d('/troubleshooting/clear_onboarding'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid),
                confirm
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        updateDealershipName: ({ cid, name, token }) => ({
            path: f4d('/troubleshooting/update_dealership_name'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid),
                name
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        checkPermission: ({ token }) => ({
            path: f4d('/troubleshooting/permissions'),
            method: 'GET',
            body: {},
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        })
    },
    mimir: {
        getUserInfo: ({ email, token }) => ({
            path: f4d('/troubleshooting/get_user_info'),
            method: 'POST',
            body: {
                email
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        getDealershipInfo: ({ cid, token }) => ({
            path: f4d('/troubleshooting/get_dealership_info'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid)
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        countBackups: ({ cid, token }) => ({
            path: mimir('/backups/Count'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid)
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        listBackups: ({ cid, token, limit, page }) => ({
            path: mimir('/backups/List'),
            method: 'POST',
            body: {
                cid: Number.parseInt(cid),
                limit,
                // Here we handle a case where the UI is zero indexed, but the API is 1 indexed and
                // converts a 0 into 1 which caused seeming duplicates in the UI on first and second pages
                page: page + 1
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        }),
        listEmails: ({ email, token, limit, cursor }) => ({
            path: mimir('/emails/List'),
            method: 'POST',
            body: {
                email,
                limit,
                cursor
            },
            opts: {
                headers: { Authorization: `Bearer ${token}` }
            }
        })
    },
    integrationServer: {
        viewInventory: ({ token, cid }) => ({
            path: integrationserver('/api/vehicles/'),
            method: 'GET',
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Frazer-CID': cid
                }
            }
        }),
        viewLeads: ({ token, cid }) => ({
            path: integrationserver('/api/leads/'),
            method: 'GET',
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-Frazer-CID': cid
                }
            }
        }),
        viewLinks: ({ token, cid, code }) => {
            const headers = {
                Authorization: `Bearer ${token}`
            }
            if (cid) {
                headers['X-Frazer-CID'] = cid
            } else {
                headers['X-Partner-Code'] = code
            }

            return {
                path: integrationserver('/api/link/'),
                method: 'GET',
                opts: {
                    headers
                }
            }
        },
        checkPermission: ({ token }) => {
            const headers = {
                Authorization: `Bearer ${token}`
            }
            return {
                path: integrationserver('/api/metadata/permissions'),
                method: 'GET',
                opts: {
                    headers
                }
            }
        }
    },
    forcedUpdates: {
        createRule: ({
            min_version, // int
            max_version, // int
            db_type, // string enum ( "BOTH", "TPS", "SQL" )
            public_description, // string
            private_description, // string
            cids, // []int32
            states, // []string
            effective_date, // time.Time ( javascript Date.toJSON() )
            who_created, // string
            token
        }) => ({
            path: versionupdates(`/tyr/CreateRule`),
            method: 'POST',
            body: {
                min_version,
                max_version,
                db_type,
                public_description,
                private_description,
                cids,
                states,
                effective_date,
                who_created
            },
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        getRule: ({ id, token }) => ({
            path: versionupdates(`/tyr/GetRule`),
            method: 'POST',
            body: { id },
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        updateRule: ({
            id,
            min_version,
            max_version,
            db_type,
            public_description,
            private_description,
            cids,
            states,
            effective_date,
            who_created,
            token
        }) => ({
            path: versionupdates(`/tyr/UpdateRule`),
            method: 'POST',
            body: {
                id,
                min_version,
                max_version,
                db_type,
                public_description,
                private_description,
                cids,
                states,
                effective_date,
                who_created
            },
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        deleteDraftedRule: ({ id, token }) => ({
            path: versionupdates(`/tyr/DeleteDraftedRule`),
            method: 'POST',
            body: { id },
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        publishRule: ({ id, who_published, token }) => ({
            path: versionupdates(`/tyr/PublishRule`),
            method: 'POST',
            body: {
                id,
                who_published
            },
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        unpublishRule: ({ id, who_unpublished, token }) => ({
            path: versionupdates(`/tyr/UnpublishRule`),
            method: 'POST',
            body: {
                id,
                who_unpublished
            },
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        listRules: ({ token }) => ({
            path: versionupdates(`/tyr/ListRules`),
            method: 'POST',
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }),
        checkPermission: ({ token }) => ({
            path: versionupdates(`/tyr/CheckAccess`),
            method: 'POST',
            opts: {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        })
    }
}

export default endpoints
