import { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { useHistory } from 'react-router-dom'
import { Button, Grid, TextField, Typography } from '@material-ui/core'

const Search = () => {
    // Router
    let history = useHistory()
    // Field state
    const [entry, setEntry] = useState('')
    // Navigating to page when initiating search (either hitting enter or clicking button)
    const goTo = () => {
        // Determine if we need to go to Dealership or User page
        // Check entry to see if it only contains numbers
        if (/^\d+$/.test(entry)) {
            // We're searching for a CID, go to dealership page
            history.push(`/dealership/${entry}`)
        } else {
            // Assume we're searching for an email address, go to user
            history.push(`/user/${entry}`)
        }
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography>Search for a Dealership or User:</Typography>
            </Grid>
            <Grid container spacing={3} item xs={12}>
                <Grid item xs={9}>
                    <TextField
                        fullWidth
                        value={entry}
                        onChange={(e) => setEntry(e.target.value)}
                        onKeyPress={(e) => {
                            if (
                                e.code === 'Enter' ||
                                e.code === 'NumpadEnter'
                            ) {
                                goTo()
                            }
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button onClick={goTo} variant="contained">
                        <SearchIcon /> Search
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Search
