import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Paper,
    Grid,
    Typography,
    TextField,
    Checkbox
} from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Link as RouterLink, Redirect, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useEffect, useState } from 'react'
import { formatDate, formatArrays, formatVersions } from '../shared'
import { useAuth } from '../contexts/Auth'
import { CreateOrEditRuleModal } from './ForcedUpdatesPage'

// Constants for how we define the status of a rule
const ruleDrafted = 'Drafted'
const rulePublished = 'Published'
const ruleUnpublished = 'Unpublished'

// Take in a rule and output a string that shows the status
const determineStatus = (published, who_unpublished) => {
    // Is it even published?
    if (published) {
        // Yes, it is currently published and active
        return rulePublished
    } else {
        // Not published, it is a fresh drafted rule, or one that has been unpublished?
        if (who_unpublished === '') {
            // No one unpublished it, this indicates it is a drafted rule
            return ruleDrafted
        }
        // If we haven't returned yet, it means it is actually Unpublished
        return ruleUnpublished
    }
}

const ForcedUpdateDetailPage = () => {
    // In the event a Drafted Rule is deleted, we need to navigate back to Forced Updates page
    const [navTo, setNavTo] = useState('') // Once set, this will render a <Redirect> component
    // Function to get auth service token to make requests to the API
    const { getFzAuthSvcToken, loggedInUsername } = useAuth()
    // We need the ability to show success or error feedback for your modals
    const [modalStatus, setModalStatus] = useState('') // '', 'success', 'error'
    // State to hold the token
    const [token, setToken] = useState('')
    // Create an async function to fetch the token and update our state
    const getAuthToken = async () => {
        // Get the token
        var t = await getFzAuthSvcToken()
        // Update our state
        setToken(t)
    }
    // Get the token only when the component first mounts
    useEffect(() => {
        getAuthToken()
        // I want an empty effect dependency array to simulate a "component did mount" event
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Determines if modals is shown or not
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    // Confirmation checkbox state
    const confirmationText =
        'I have confirmed this Forced Update is ready to be published.'
    const [confirmation, setConfirmation] = useState({
        checkBox1: false,
        checkBox2: false,
        checkBox3: false,
        text: '',
        confirmed: false
    })
    // Update our confirmed status when input changes
    useEffect(() => {
        // Have the requirements been met?
        if (
            confirmation.checkBox1 &&
            confirmation.checkBox2 &&
            confirmation.checkBox3 &&
            confirmation.text === confirmationText
        ) {
            // We're good, set confirmed status if it hasn't already been set,
            // otherwise this will keep throw errors in console as confirmation
            // will constantly keep being changed and force an infinite loop
            if (confirmation.confirmed === false) {
                setConfirmation((cf) => ({
                    ...cf,
                    confirmed: true
                }))
            }
        }
    }, [confirmation])
    // If any errors occur making API calls, we need to inform the user something happened
    const [errMsg, setErrMsg] = useState('')
    // This determines the behavior of the action button
    const [ruleStatus, setRuleStatus] = useState('')
    // API Interaction
    const api = useAPIClient()
    // Get the ID from the path parameter
    var { id } = useParams()
    // Get rule data
    const { data: resp, refetch: getRule } = useQuery(
        ['getRule', Number(id)],
        () => api.forcedUpdates.getRule({ id: Number(id), token }),
        {
            enabled: !!token
        }
    )
    // Update our Rule Status when we fetch data
    useEffect(() => {
        if (resp?.rule) {
            setRuleStatus(
                determineStatus(resp.rule.published, resp.rule.who_unpublished)
            )
        }
    }, [resp])

    // Delete Drafted Rule
    const { mutate: deleteDraftedRule, isLoading: deleteIsLoading } =
        useMutation(
            () =>
                api.forcedUpdates.deleteDraftedRule({
                    id: Number(id),
                    token
                }),
            {
                onSuccess: () => {
                    // Display success and set the navTo to the main Forced Updates page
                    setModalStatus('success')
                    setNavTo('/forced-updates')
                },
                onError: (err) => {
                    // Log the error with console.log as it is an internal tool
                    console.log('Error: ', err)
                    // If we encountered an error, inform the user
                    if (err?.message) {
                        setErrMsg(err.message)
                    } else {
                        setErrMsg(
                            'An error occurred when trying to delete drafted forced update. Please try again.'
                        )
                    }
                    // Display error to the user
                    setModalStatus('error')
                }
            }
        )
    // DeleteDraftedRule Handler
    const deleteDraftedRuleHandler = () => {
        // Prevent firing multiple API calls
        if (deleteIsLoading) {
            return
        }
        // Fire API call
        deleteDraftedRule()
    }

    // Publish Rule
    const { mutate: publishRule, isLoading: publishIsLoading } = useMutation(
        () =>
            api.forcedUpdates.publishRule({
                id: Number(id),
                who_published: loggedInUsername,
                token
            }),
        {
            onSuccess: () => {
                // Display success and refetch fresh data
                setModalStatus('success')
                getRule()
            },
            onError: (err) => {
                // Log the error with console.log as it is an internal tool
                console.log('Error: ', err)
                // If we encountered an error, inform the user
                if (err?.message) {
                    setErrMsg(err.message)
                } else {
                    setErrMsg(
                        'An error occurred when trying to publish forced update. Please try again.'
                    )
                }
                // Display error to the user
                setModalStatus('error')
            }
        }
    )
    // Publish Handler
    const publishRuleHandler = () => {
        // Ensure we're not already firing this API call
        if (publishIsLoading) {
            // We are, do nothing
            return
        }
        // Do we have confirmation?
        if (!confirmation.confirmed) {
            // No we do not, do nothing
            return
        }
        // Ensure the same user that created the rule cannot also publish it
        // (Only for production environment)
        if (process.env.NODE_ENV === 'production') {
            if (
                resp?.rule.who_created.toLowerCase() ===
                loggedInUsername.toLowerCase()
            ) {
                // Prevent user from publishing
                setModalStatus('error')
                setErrMsg(
                    'A User cannot publish a forced update that they have created. Inform another authorized user to verify your draft, and they can publish the forced update.'
                )
                return
            }
        }
        // Publish it
        publishRule()
    }

    // Unpublish Rule
    const { mutate: unpublishRule, isLoading: unpublishIsLoading } =
        useMutation(
            () =>
                api.forcedUpdates.unpublishRule({
                    id: Number(id),
                    who_unpublished: 'test.tester@leadventure.com',
                    token
                }),
            {
                onSuccess: () => {
                    // Display success and refresh data
                    setModalStatus('success')
                    getRule()
                },
                onError: (err) => {
                    // Log the error with console.log as it is an internal tool
                    console.log('Error: ', err)
                    // If we encountered an error, inform the user
                    if (err?.message) {
                        setErrMsg(err.message)
                    } else {
                        setErrMsg(
                            'An error occurred when trying to unpublish forced update. Please try again.'
                        )
                    }
                    // Display error the user
                    setModalStatus('error')
                }
            }
        )
    // Unpublish handler
    const unpublishRuleHandler = () => {
        // Prevent firing multiple API calls
        if (publishIsLoading) {
            return
        }
        // Fire API call
        unpublishRule()
    }

    // Navigate if we have a nav path and the modal has closed
    if (navTo !== '' && showDeleteModal === false) {
        return <Redirect to={navTo} />
    }

    return (
        <>
            <Paper elevation={5}>
                <Grid container spacing={3}>
                    <Grid container justifyContent="space-between" item xs={12}>
                        <Grid item style={{ paddingLeft: 24 }}>
                            <Typography variant="h5">
                                <b>Forced Update Details</b>
                            </Typography>
                        </Grid>
                        <Grid item style={{ paddingRight: 24 }}>
                            <Link
                                component={RouterLink}
                                to="/forced-updates"
                            >{`<- Back to Forced Updates`}</Link>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Status:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{ruleStatus}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Effective Date:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>
                                {resp?.rule &&
                                    formatDate(resp.rule.effective_date)}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Date Unpublished:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>
                                {resp?.rule && resp.rule.who_unpublished !== ''
                                    ? formatDate(resp.rule.modified)
                                    : 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Versions:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule &&
                                    formatVersions(
                                        resp.rule.min_version,
                                        resp.rule.max_version
                                    )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Reason:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule.private_description}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Customer Message:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule.public_description}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>System Type:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule && resp.rule.db_type}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>List of States:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                value={
                                    resp?.rule
                                        ? formatArrays(resp.rule.states)
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>List of CIDs:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                multiline
                                variant="filled"
                                value={
                                    resp?.rule
                                        ? formatArrays(resp.rule.cids)
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Who Created:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{resp?.rule.who_created}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Who Published:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>{resp?.rule.who_published}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Who Unpublished:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule.who_unpublished}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Date Created:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule && formatDate(resp.rule.created)}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} item xs={12}>
                        <Grid container justifyContent="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Date Modified:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography>
                                {resp?.rule && formatDate(resp.rule.modified)}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="flex-end" item xs={12}>
                        {ruleStatus === ruleDrafted && (
                            <>
                                <Grid item style={{ paddingRight: 24 }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setShowDeleteModal(true)}
                                    >
                                        Delete Drafted Rule
                                    </Button>
                                </Grid>
                                <Grid item style={{ paddingRight: 24 }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => setShowEditModal(true)}
                                    >
                                        Modify Rule
                                    </Button>
                                </Grid>
                                <Grid item style={{ paddingRight: 24 }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                            setShowConfirmationModal(true)
                                        }
                                        disabled={
                                            publishIsLoading ||
                                            resp?.rule.who_created.toLowerCase() ===
                                                loggedInUsername.toLowerCase()
                                        }
                                    >
                                        Publish this Forced Update
                                    </Button>
                                </Grid>
                            </>
                        )}
                        {ruleStatus === rulePublished && (
                            <Grid item style={{ paddingRight: 24 }}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                        setShowConfirmationModal(true)
                                    }
                                >
                                    Unpublish this Forced Update
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Paper>

            <Dialog
                open={showConfirmationModal}
                onClose={() => {
                    // This can fire if user clicks outside of modal, and not the "Cancel" button
                    setModalStatus('')
                    setShowConfirmationModal(false)
                }}
                fullWidth={true}
                maxWidth={modalStatus === '' ? 'sm' : 'xs'}
            >
                <DialogTitle>
                    {ruleStatus === ruleDrafted &&
                        modalStatus === '' &&
                        'Are you sure you wish to Publish this Forced Update?'}
                    {ruleStatus === rulePublished &&
                        modalStatus === '' &&
                        'Are you sure you with to Unpublish this Forced Update?'}
                </DialogTitle>
                <DialogContent>
                    {modalStatus === '' && (
                        <Grid container>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <HelpOutlineIcon
                                        style={{ height: 128, width: 128 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2}>
                                {ruleStatus === ruleDrafted && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography>
                                                To confirm you wish to Publish
                                                this Forced Update:
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                item
                                                xs={3}
                                            >
                                                <Grid item>
                                                    <Checkbox
                                                        value={
                                                            confirmation.checkBox1
                                                        }
                                                        onChange={(e) =>
                                                            setConfirmation(
                                                                (c) => ({
                                                                    ...c,
                                                                    confirmed: false,
                                                                    checkBox1:
                                                                        e.target
                                                                            .value
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    I confirm that I have
                                                    reviewed the Drafted Rule
                                                    and confirmed the details
                                                    are correct.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                item
                                                xs={3}
                                            >
                                                <Grid item>
                                                    <Checkbox
                                                        value={
                                                            confirmation.checkBox2
                                                        }
                                                        onChange={(e) =>
                                                            setConfirmation(
                                                                (c) => ({
                                                                    ...c,
                                                                    confirmed: false,
                                                                    checkBox2:
                                                                        e.target
                                                                            .value
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    I understand that by
                                                    publishing this Rule, all
                                                    dealers that are affected by
                                                    this will be forced to
                                                    update on the Effective
                                                    Date.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid
                                                container
                                                justifyContent="center"
                                                item
                                                xs={3}
                                            >
                                                <Grid item>
                                                    <Checkbox
                                                        value={
                                                            confirmation.checkBox3
                                                        }
                                                        onChange={(e) =>
                                                            setConfirmation(
                                                                (c) => ({
                                                                    ...c,
                                                                    confirmed: false,
                                                                    checkBox3:
                                                                        e.target
                                                                            .value
                                                                })
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography>
                                                    I understand that if
                                                    anything needs to be
                                                    modified with this rule once
                                                    it is published, I will have
                                                    to unpublish it, and create
                                                    a fresh rule.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Finally, confirm by typing the
                                                following sentence into the text
                                                field below:{' '}
                                                <b>{confirmationText}</b>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                value={confirmation.text}
                                                onChange={(e) =>
                                                    setConfirmation((c) => ({
                                                        ...c,
                                                        confirmed: false,
                                                        text: e.target.value // No need to sanitize this input, as it is not being used to be sent to backend.
                                                    }))
                                                }
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {modalStatus === 'error' && (
                        <Grid container>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        Something went wrong...
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <ErrorOutlineIcon
                                        style={{ height: 128, width: 128 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        {errMsg}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {modalStatus === 'success' && (
                        <Grid container>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        Operation Successful
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <CheckCircleOutlineIcon
                                        style={{ height: 128, width: 128 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setModalStatus('')
                            setShowConfirmationModal(false)
                        }}
                    >
                        Close
                    </Button>
                    {ruleStatus === ruleDrafted && modalStatus === '' && (
                        <Button
                            disabled={
                                !confirmation.confirmed || publishIsLoading
                            }
                            onClick={() => {
                                // Attempt to fire API call (confirmation validation is handled in the handler)
                                publishRuleHandler()
                            }}
                        >
                            {publishIsLoading
                                ? 'Publishing Forced Update...'
                                : 'Publish Forced Update'}
                        </Button>
                    )}
                    {ruleStatus === rulePublished && modalStatus === '' && (
                        <Button
                            disabled={unpublishIsLoading}
                            onClick={() => {
                                // Attempt to fire API call
                                unpublishRuleHandler()
                            }}
                        >
                            {unpublishIsLoading
                                ? 'Unpublishing Force Update...'
                                : 'Unpublish Forced Update'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog
                open={showDeleteModal}
                onClose={() => {
                    // This can fire if user clicks outside of modal, and not the "Cancel" button
                    setModalStatus('')
                    setShowDeleteModal(false)
                }}
                fullWidth={true}
                maxWidth={'xs'}
            >
                <DialogTitle>Delete Drafted Rule</DialogTitle>
                <DialogContent>
                    {modalStatus === '' && (
                        <Grid container>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        Are you sure you wish to delete this
                                        Drafted Forced Update?
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <HelpOutlineIcon
                                        style={{ height: 128, width: 128 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {modalStatus === 'error' && (
                        <Grid container>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        Something went wrong...
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <ErrorOutlineIcon
                                        style={{ height: 128, width: 128 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        {errMsg}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {modalStatus === 'success' && (
                        <Grid container>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <Typography variant="h6">
                                        Operation Successful
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                item
                                xs={12}
                            >
                                <Grid item>
                                    <CheckCircleOutlineIcon
                                        style={{ height: 128, width: 128 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setModalStatus('')
                            setShowDeleteModal(false)
                        }}
                    >
                        {modalStatus === 'error' ? 'Close' : 'Cancel'}
                    </Button>
                    {modalStatus === '' && (
                        <Button onClick={deleteDraftedRuleHandler}>
                            Delete Drafted Update
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {resp?.rule && (
                <CreateOrEditRuleModal
                    open={showEditModal}
                    close={() => setShowEditModal(false)}
                    loggedInUsername={loggedInUsername}
                    token={token}
                    refetch={getRule}
                    existingRule={resp?.rule}
                />
            )}
        </>
    )
}

export default ForcedUpdateDetailPage
