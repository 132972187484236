import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Link from '@material-ui/core/Link'
import Toolbar from '@material-ui/core/Toolbar'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MSLoginButton from '../components/MSLoginButton'
import { useAuth } from '../contexts/Auth'
import {
    Root,
    getHeader,
    getContent,
    getDrawerSidebar,
    getSidebarContent,
    getSidebarTrigger,
    getMuiTreasuryScheme
} from '@mui-treasury/layout'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Tooltip } from '@material-ui/core'
import { useAPIClient } from 'react-toolbox/APIClient'

// Mui Treasury Components
// https://mui-treasury.com/layout/core-concept/
const MUIHeader = getHeader(styled)
const Content = getContent(styled)
const DrawerSidebar = getDrawerSidebar(styled)
const SidebarContent = getSidebarContent(styled)
const SidebarTrigger = getSidebarTrigger(styled)
const tooltip = 'Unauthorized. If this is incorrect, ask your supervisor.'

// Using MUITreasury scheme since it suits our needs, no need to reinvent the wheel.
// https://mui-treasury.com/layout/presets/mui-treasury/
const standardScheme = getMuiTreasuryScheme()

const SidebarLayout = ({ children }) => {
    // Hook to get information regarding logged in user.
    const {
        login,
        loggedIn,
        getMSUserInfo,
        getFzAuthSvcToken,
        loginInProgress
    } = useAuth()

    const defaultMenuItems = {
        mimir: {
            text: 'Mimir',
            icon: <ListAltIcon />,
            path: '/search',
            tooltip: ''
        },
        versionupdates: {
            text: 'Forced Updates',
            icon: <SystemUpdateAltIcon />,
            path: '/forced-updates',
            // TODO: All software engineers can at least view forced updates
            disabled: true,
            tooltip
        },
        passwordsystem: {
            text: 'Password System',
            icon: <SettingsApplicationsIcon />,
            path: '/password-system',
            disabled: true,
            tooltip
        },
        authservice: {
            text: 'Auth Service',
            icon: <VpnKeyIcon />,
            path: '/auth-service',
            disabled: true,
            tooltip
        },
        integrationserver: {
            text: 'Integration Server',
            icon: <SettingsApplicationsIcon />,
            path: '/integration-server',
            disabled: true,
            tooltip
        }
    }
    // Populate with list items for side drawer depending on environment.
    const [menuItems, setMenuItems] = useState(defaultMenuItems)
    const api = useAPIClient()

    // contains companyName, msToken, and jobTitle for preliminary authorization
    const [userDetails, setUserDetails] = useState()
    // Function to populate menu once logged in
    const populateMenu = async () => {
        // Are we logged in?
        if (!loggedIn) {
            // We must've just logged out, clear menu items and do nothing else
            setMenuItems(defaultMenuItems)
            return
        }

        // we need to have both of our tokens here for this to work.
        const userInfo = await getMSUserInfo()
        const authSvcToken = await getFzAuthSvcToken()
        setUserDetails(userInfo)
        // Check with each of the servers if the given user should have access
        api.authSvc
            .checkPermission({ token: authSvcToken })
            .then((r) => {
                r.allowed &&
                    setMenuItems((cv) => ({
                        ...cv,
                        authservice: {
                            ...cv.authservice,
                            disabled: false,
                            tooltip: ''
                        }
                    }))
            })
            .catch((r) =>
                console.log(
                    `error checking auth service permissions: ${JSON.stringify(
                        r
                    )}`
                )
            )
        api.integrationServer
            .checkPermission({ token: authSvcToken })
            .then((r) => {
                r.allowed &&
                    setMenuItems((cv) => ({
                        ...cv,
                        integrationserver: {
                            ...cv.integrationserver,
                            disabled: false,
                            tooltip: ''
                        }
                    }))
            })
            .catch((r) =>
                console.log(
                    `error checking integration server permissions: ${JSON.stringify(
                        r
                    )}`
                )
            )

        api.nps
            .checkPermission({ token: userInfo?.msToken })
            .then((r) => {
                // Password system returns all data within a {data:{}} struct
                r.data.allowed &&
                    setMenuItems((cv) => ({
                        ...cv,
                        passwordsystem: {
                            ...cv.passwordsystem,
                            disabled: false,
                            tooltip: ''
                        }
                    }))
            })
            .catch((r) =>
                console.log(
                    `error checking password system permissions: ${JSON.stringify(
                        r
                    )}`
                )
            )
        // All devs can list version updates
        userInfo?.jobTitle.includes('Software Engineer') ||
        userInfo?.jobTitle.includes('Software Developer')
            ? setMenuItems((cv) => ({
                  ...cv,
                  versionupdates: {
                      ...cv.versionupdates,
                      disabled: false,
                      tooltip: ''
                  }
              }))
            : api.forcedUpdates
                  .checkPermission({ token: authSvcToken })
                  .then((r) => {
                      r.allowed &&
                          setMenuItems((cv) => ({
                              ...cv,
                              versionupdates: {
                                  ...cv.versionupdates,
                                  disabled: false,
                                  tooltip: ''
                              }
                          }))
                  })
                  .catch((r) =>
                      console.log(
                          `error checking forced updates permissions: ${JSON.stringify(
                              r
                          )}`
                      )
                  )
    }

    // Update menu items whenever loggedIn changes
    useEffect(() => {
        populateMenu()
        // Adding populateMenu to this dependency array causes an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn])

    return (
        <Root scheme={standardScheme} themeProviderOmitted={true}>
            <CssBaseline />
            <Header userPrincipalName={userDetails?.userPrincipalName} />
            <DrawerSidebar
                sidebarId="primarySidebar"
                PaperProps={{
                    square: true
                }}
            >
                <SidebarContent>
                    <List>
                        {
                            // If they aren't a member of Frazer, don't let them in.
                            userDetails?.frazerEmployee &&
                                Object.keys(menuItems).map((k) => (
                                    <div key={menuItems[k].text + 'div'}>
                                        <Tooltip title={menuItems[k].tooltip}>
                                            <Link
                                                component={
                                                    menuItems[k].disabled
                                                        ? null
                                                        : RouterLink
                                                }
                                                to={menuItems[k].path}
                                            >
                                                <ListItem
                                                    button
                                                    key={menuItems[k].text}
                                                    disabled={
                                                        menuItems[k].disabled
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        {menuItems[k].icon}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            menuItems[k].text
                                                        }
                                                    />
                                                </ListItem>
                                            </Link>
                                        </Tooltip>
                                        <Divider />
                                    </div>
                                ))
                        }
                    </List>
                </SidebarContent>
            </DrawerSidebar>
            <Content style={{ padding: '24px' }}>
                {!loggedIn ? (
                    <>
                        {loginInProgress ? (
                            <Typography>Login in progress...</Typography>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography>Please log in.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MSLoginButton
                                        onClick={() => {
                                            login()
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>
                ) : // consider msal token claims
                userDetails === undefined ? (
                    // We're still loading user details. Don't return content yet.
                    <></>
                ) : !userDetails?.frazerEmployee ? (
                    <Typography variant="h6" color="error">
                        Access Denied. If you believe this is an error, please
                        contact an administrator
                    </Typography>
                ) : (
                    children
                )}
            </Content>
        </Root>
    )
}

export default SidebarLayout

SidebarLayout.propTypes = {
    children: PropTypes.element.isRequired
}

const Header = ({ userPrincipalName }) => {
    const classes = useStyles()
    const { loggedIn } = useAuth()

    let env = 'LOCAL'

    // Determine what version of Tyr we are running to set the header label
    switch (process.env.REACT_APP_STAGE.toUpperCase()) {
        case 'STAGING':
            env = 'TEST'
            break
        case 'PRODUCTION':
            env = 'LIVE'
            break
        default:
            env = 'LOCAL'
    }

    return (
        <MUIHeader color="primary" elevation={1} style={{ height: 72 }}>
            <Toolbar>
                <SidebarTrigger sidebarId="primarySidebar" color="secondary" />
                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar>
                            <Grid container>
                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    item
                                    xs={4}
                                >
                                    <Grid item>
                                        <Typography
                                            className={classes.title}
                                            variant="h6"
                                            noWrap
                                        >
                                            TYR
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="center"
                                    item
                                    xs={4}
                                >
                                    <Grid item>
                                        <Typography>
                                            Environment: {env}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    item
                                    xs={4}
                                >
                                    <Grid item xs>
                                        <Typography
                                            noWrap
                                            style={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap'
                                            }}
                                        >
                                            {loggedIn
                                                ? `Logged in as ${
                                                      userPrincipalName || ''
                                                  }`
                                                : 'Not Logged In'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </div>
            </Toolbar>
        </MUIHeader>
    )
}

Header.propTypes = {
    userPrincipalName: PropTypes.string
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        }
    }
}))
