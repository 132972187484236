import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import WrappedEntry from './components/WrappedEntry'
import PasswordSystemPage from './pages/PasswordSystemPage'
import IntegrationServerPage from './pages/IntegrationServerPage'
import DealershipPage from './pages/DealershipPage'
import SearchPage from './pages/SearchPage'
import UserPage from './pages/UserPage'
import BackupsPage from './pages/BackupsPage'
import AuthServicePage from './pages/AuthServicePage'
import ForcedUpdatePage from './pages/ForcedUpdatesPage'
import ForcedUpdateDetailPage from './pages/ForcedUpdateDetailPage'

function App() {
    return (
        <Router>
            <WrappedEntry>
                <Switch>
                    <Route path="/password-system">
                        <PasswordSystemPage />
                    </Route>
                    <Route path="/integration-server">
                        <IntegrationServerPage />
                    </Route>
                    <Route path="/search">
                        <SearchPage />
                    </Route>
                    <Route path="/dealership/:cid/backup">
                        <BackupsPage />
                    </Route>
                    <Route path="/dealership/:cid">
                        <DealershipPage />
                    </Route>
                    <Route path="/user/:email">
                        <UserPage />
                    </Route>
                    <Route path="/auth-service">
                        <AuthServicePage />
                    </Route>
                    <Route path="/forced-updates">
                        <ForcedUpdatePage />
                    </Route>
                    <Route path="/forced-updates-detail/:id">
                        <ForcedUpdateDetailPage />
                    </Route>
                </Switch>
            </WrappedEntry>
        </Router>
    )
}

export default App
