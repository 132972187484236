import { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'

/** A Compact Table with sorting capability that will span the width of its' container.
 * @param {Object[]} headers Array of header objects, determines sorting and text of the headers for each column
 * @param {boolean} headers[].sortable Determines whether or not the user can click the header to sort data
 * @param {string} [headers[].sortType] Determines the sorting method. Expected values: 'string', 'number', 'boolean', 'array', defaults to string. Optional if sortable is false.
 * @param {string} headers[].label Text that labels the column in the Table
 * @param {Object[]} tableData The array of data to be sorted and rendered in the table
 * @param {string} [tableData[].navpath] Optional path to navigate to when the row of table is clicked
 * @param {boolean} [tableData[].clickDisabled] Optional parameter that determines if a row should be clickable. Unless specified default behavior is to allow rows to be clicked.
 * @param {Object[]} tableData[].row Row in the table
 * @param {Function} [tableData[].row[].formatter] Optional formatter function that the table will apply to data
 * @param {Function} [tableData[].row[].clickHandler] Optional handler function for if you wish an individual cell to be clickable (if the cell contains a button). Requires the row's clickDisabled to be true if the cell needs to be clickable.
 * @param {*} tableData[].row[].data The actual data for the table cell
 */
const TableCompact = ({ headers, tableData }) => {
    // Sorted Array for Table
    const [sortedData, setSortedData] = useState(tableData ? tableData : [])
    // Selected sort column
    const [selectedColumn, setSelectedColumn] = useState(0)
    // Sorting Direction (asc, desc)
    const [sortDirection, setSortDirection] = useState('asc')
    // This is an ugly workaround for programatically navigating to another page when a row is clicked
    // (React router dom doesn't have a hook or function, we have to use a <Redirect/> component)
    // We'll check for the presence of a path, if one exists, immediate render a redirect component
    // which will navigate to the desired page
    const [navToPath, setNavToPath] = useState('')

    /**
     * Handler for when column header is clicked, determines sort
     * @param {number} index - index of the clicked header in the header array, determines sort column
     */
    const handleColumnClick = (index) => {
        // Determine if this column we clicked is already selected
        if (selectedColumn === index) {
            // It is, toggle sort direction between asc and desc
            sortDirection === 'asc'
                ? setSortDirection('desc')
                : setSortDirection('asc')
        } else {
            // It is not, set the sort direction to asc by default, and set our selected column
            setSelectedColumn(index)
            setSortDirection('asc')
        }
    }
    // Sort table whenever data, selected column, or sort direction changes
    useEffect(() => {
        // Do we even have data to sort?
        if (!tableData) return
        // Is our selected column sortable?
        if (!headers[selectedColumn].sortable) return
        // Yes, sort the data (Ascending by default)
        let sortArray = sortTableData(
            tableData,
            selectedColumn,
            headers[selectedColumn].sortType
        )
        // Descending (if specified)
        if (sortDirection === 'desc') {
            sortArray = sortArray.reverse()
        }
        // Update the state, fresh array to force render
        setSortedData([...sortArray])
    }, [headers, tableData, selectedColumn, sortDirection])

    // Check for existence of a path whenever the value of navToPath changes
    if (navToPath !== '') {
        return <Redirect to={navToPath} />
    }
    return (
        <TableContainer
            style={{
                width: '100%',
                overflowX: 'auto',
                overflow: 'auto',
                maxHeight: 600
            }}
        >
            <Table size="small" stickyHeader>
                <TableHead>
                    <TableRow key="header-row">
                        {headers &&
                            headers.length > 0 &&
                            headers.map((header, index) => {
                                return (
                                    <TableCell
                                        key={`${index}-${header.label}-header`}
                                    >
                                        {header.sortable ? (
                                            <TableSortLabel
                                                active={
                                                    selectedColumn === index
                                                }
                                                direction={sortDirection}
                                                onClick={() =>
                                                    handleColumnClick(index)
                                                }
                                            >
                                                {header.label}
                                            </TableSortLabel>
                                        ) : (
                                            header.label
                                        )}
                                    </TableCell>
                                )
                            })}
                    </TableRow>
                </TableHead>
                <TableBody key="table-body">
                    {sortedData &&
                        sortedData.length > 0 &&
                        sortedData.map((data, index) => (
                            <TableRow
                                key={`${index}-tablerow`}
                                hover
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    // Navigate, unless row click is disabled.
                                    if (data?.clickDisabled) return
                                    if (data?.navpath) {
                                        setNavToPath(data.navpath)
                                    }
                                }}
                            >
                                {data.row &&
                                    data.row.length > 0 &&
                                    data.row.map((cell, i) => {
                                        return (
                                            <TableCell
                                                key={`${index}-row-${i}-cell`}
                                                onClick={() => {
                                                    if (cell?.clickHandler) {
                                                        cell.clickHandler()
                                                    }
                                                }}
                                            >
                                                {cell.formatter
                                                    ? cell.formatter(cell.data)
                                                    : cell.data}
                                            </TableCell>
                                        )
                                    })}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableCompact

TableCompact.propTypes = {
    /** Array containing the header objects for each column */
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            sortable: PropTypes.bool,
            sortType: PropTypes.string,
            label: PropTypes.string
        })
    ),
    /** Array of objects, contains the navpath when a row is clicked, and a nested array containing the data cells */
    tableData: PropTypes.arrayOf(
        PropTypes.shape({
            navpath: PropTypes.string,
            row: PropTypes.arrayOf(
                PropTypes.shape({
                    formatter: PropTypes.func,
                    clickHandler: PropTypes.func,
                    data: PropTypes.any
                })
            )
        })
    )
}

/**
 * Sorts a tableData array by specified index (column). Returns an array sorted by ascending order.
 * If you wish for descending, simply follow this sort by a .reverse().
 * If unable to sort due to malformed tableData, it will simply return the unsorted data
 * @param {Object[]} tableData The array of data to be sorted
 * @param {string} [tableData[].navpath] Optional path to navigate to when the row of table is clicked
 * @param {boolean} [tableData[].clickDisabled] Optional parameter that determines if a row should be clickable. Unless specified default behavior is to allow rows to be clicked.
 * @param {Object[]} tableData[].row Row in the table
 * @param {Function} [tableData[].row[].formatter] Optional formatter function that the table will apply to data
 * @param {*} tableData[].row[].data The actual data for the table cell
 * @param {number} sortColumn The index to sort the array of arrays by (basically specifies the column)
 * @param {string} [sortType=string] Specifying the type improves sort results. Defaults to "string", other acceptable values: "number", "boolean", "array", anything else will not sort properly
 * @returns {[]} Sorted Array in ascending order
 */
const sortTableData = (tableData, sortColumn, sortType = 'string') => {
    // If tableData is malformed, it throws errors
    try {
        return tableData.sort((a, b) => {
            // Do both A and B match the specified type? (Arrays are typeof 'object', not 'array', we handle those farther down)
            if (
                typeof a.row[sortColumn].data === sortType &&
                typeof b.row[sortColumn].data === sortType
            ) {
                // Yes, then compare them
                switch (sortType) {
                    case 'string':
                        return a.row[sortColumn].data.localeCompare(
                            b.row[sortColumn].data
                        )
                    case 'number':
                    case 'boolean':
                        return a.row[sortColumn].data - b.row[sortColumn].data
                    default:
                        // Not an Array or other type that we expect, just move it up
                        return -1
                }
            }
            // No, does at least A match the keyType?
            if (typeof a.row[sortColumn].data === sortType) {
                // Yes, move it down the array
                return 1
            }
            // No, is our sortType 'array'?
            if (sortType === 'array') {
                // Yes, are both A and B arrays?
                if (
                    Array.isArray(a.row[sortColumn].data) &&
                    Array.isArray(b.row[sortColumn].data)
                ) {
                    // Yes, compare the arrays as strings by joining them
                    return a.row[sortColumn].data
                        .join()
                        .localeCompare(b.row[sortColumn].data.join())
                }
                // No, is at least A an array?
                if (Array.isArray(a.row[sortColumn].data)) {
                    // Yes, move it down the array
                    return 1
                }
                // No, move it up
                return -1
            }
            // No, just move it up
            return -1
        })
    } catch (error) {
        // TODO (IME): Determine what should be done with the error, if anything. For now, just console.log it
        console.log(error)
        // Failure, just return the unsorted data
        return tableData
    }
}
