import { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { useParams } from 'react-router-dom'
import { Table, Spinner, BackButton } from '../components'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Link } from 'react-router-dom'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useQuery } from 'react-query'
import { useAuth } from '../contexts/Auth'
import { formatDateTime } from '../shared'

export default function UserPage() {
    // Function to get bearer token
    const { getToken, getFzAuthSvcToken } = useAuth()
    const [token, setToken] = useState()
    const [authSvcToken, setAuthSvcToken] = useState()
    // Pagination state
    const [cursor] = useState(0)
    const [rowsPerPage] = useState(10)
    // If an error occurs fetching critical data, display a useful message to the user
    const [errorMsg, setErrorMsg] = useState('')

    // Effect callbacks are synchronous to prevent race conditions. Put the async function inside
    const obtainToken = async () => {
        let t = await getToken()
        setToken(t)
    }
    const obtainAuthSvcToken = async () => {
        let t = await getFzAuthSvcToken()
        setAuthSvcToken(t)
    }

    // Get token on first mount
    useEffect(() => {
        obtainToken()
        obtainAuthSvcToken()
        // Empty dependency array allows this to run only once on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Get the email from the parameters
    let { email } = useParams()
    // API interaction
    const api = useAPIClient()
    // Get User Info query
    const { data: resp, isLoading: userIsLoading } = useQuery(
        ['getUserInfo', email, token],
        () => api.mimir.getUserInfo({ email, token }),
        {
            enabled: !!token && !!email,
            refetchOnWindowFocus: false,
            retry: 1,
            onError: (err) => {
                // Determine type of error
                if (err.error.kind === 'resource not found') {
                    // Not found
                    setErrorMsg(`User with Email ${email} not found`)
                    return
                }
                // Some other type of error occurred
                setErrorMsg(
                    `Encountered an error querying data. Message: ${err.error.message}, Kind: ${err.error.kind}`
                )
            }
        }
    )

    // Query to get emails
    const { data: emailResp } = useQuery(
        ['listEmails', email, authSvcToken, rowsPerPage, cursor],
        () =>
            api.mimir.listEmails({
                email,
                token: authSvcToken,
                limit: rowsPerPage,
                cursor
            }),
        {
            enabled: !!authSvcToken && !!email && !!resp,
            refetchOnWindowFocus: false
        }
    )

    // If we are loading, render a spinner
    if (userIsLoading) {
        return (
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
            >
                <Grid item>
                    <Spinner />
                </Grid>
            </Grid>
        )
    }
    // Render not found if not able to query the user
    if (errorMsg) {
        return (
            <>
                <Typography variant="h6" color="error">
                    {errorMsg}
                </Typography>
                <BackButton />
            </>
        )
    }
    return (
        <Grid spacing={2} container>
            <Grid container item xs={12}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">
                                {resp?.data?.user.firstName}{' '}
                                {resp?.data?.user.lastName}
                            </Typography>
                            {email}
                            <br />
                            ID: {resp?.data.user.id}
                            <br />
                            Created: {resp?.data.user.dateCreated}
                            <br />
                            Last failed login: {resp?.data.user.lastFailedLogin}
                            <br />
                            Last successful login: {resp?.data.user.lastLogin}
                            <br />
                            Last activity: {resp?.data.user.lastActivity}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item style={{ flexGrow: 1 }} />
                <Grid item>
                    <BackButton />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Typography variant="h6">
                                    Dealerships
                                </Typography>
                            </Grid>
                            {resp?.data?.user &&
                                Object.keys(resp.data.user.dealerships).map(
                                    (key, index) => (
                                        <Grid
                                            item
                                            key={`${key}-${index}`}
                                            xs={12}
                                        >
                                            <Typography>
                                                <Link
                                                    to={`/dealership/${resp.data.user.dealerships[key].cid}`}
                                                >
                                                    {
                                                        resp.data.user
                                                            .dealerships[key]
                                                            .cid
                                                    }{' '}
                                                    -{' '}
                                                    {
                                                        resp.data.user
                                                            .dealerships[key]
                                                            .name
                                                    }
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Emails</Typography>
                        <Table
                            headers={['Timestamp', 'Category', 'Event', 'IP']}
                            rows={emailResp?.emails?.map((e, i) => {
                                return [
                                    formatDateTime(e['timestamp'] * 1000),
                                    e['category'],
                                    e['event'],
                                    e['ip']
                                ]
                            })}
                        />
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Audits</Typography>
                        <Table
                            headers={[
                                'Date & Time',
                                'CID',
                                'Admin',
                                'Name',
                                'Description'
                            ]}
                            rows={resp?.data?.events.map((a) => {
                                return [
                                    formatDateTime(a['dateCreated']),
                                    a['cid'],
                                    a['admin'] ? 'Y' : 'N',
                                    a['name'],
                                    a['description']
                                ]
                            })}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
