import { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useMutation } from 'react-query'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { useAuth } from '../contexts/Auth'

import endpoints from '../api'

export default function PasswordSystemPage() {
    // State
    const [activeForm, setActiveForm] = useState('')

    // Function to get bearer token
    const { getToken } = useAuth()

    // Constants
    const forms = {
        NEW_ADMIN: 'new_admin',
        PROMOTE_TO_ADMIN: 'promote_to_admin',
        CHECK_ONBOARDING: 'check_onboarding',
        CLEAR_ONBOARDING: 'clear_onboarding',
        CHECK_CACHE: 'check_cache',
        CLEAR_CACHE: 'clear_cache',
        UPDATE_DEALERSHIP_NAME: 'update_dealership_name'
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Promote Employee To Admin"
                    description="Promotes an existing employee to admin. For use when no existing admin account is accessible."
                    active={activeForm === forms.PROMOTE_TO_ADMIN}
                    makeActive={() => {
                        setActiveForm(forms.PROMOTE_TO_ADMIN)
                    }}
                >
                    <PromoteToAdminForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Create New Admin"
                    description="Creates a new admin-level employee. Should only be used if unable to promote an existing employee to admin. Will require that a secret button in Frazer be used to complete the operation."
                    active={activeForm === forms.NEW_ADMIN}
                    makeActive={() => {
                        setActiveForm(forms.NEW_ADMIN)
                    }}
                >
                    <NewAdminForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Check Cached Data"
                    description="Checks cached data related to a dealership. For use when troubleshooting issues suggesting that the cache may not contain correct information."
                    active={activeForm === forms.CHECK_CACHE}
                    makeActive={() => {
                        setActiveForm(forms.CHECK_CACHE)
                    }}
                >
                    <CheckCacheForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Clear Cached Data"
                    description="Clears cached data related to a dealership. For use when the Check Cache operation confirms that cached data is not accurate."
                    active={activeForm === forms.CLEAR_CACHE}
                    makeActive={() => {
                        setActiveForm(forms.CLEAR_CACHE)
                    }}
                >
                    <ClearCacheForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Check Dealership Onboarding"
                    description="Checks information related to dealership onboarding to the password system. For use when troubleshooting issues related to a broken onboarding attempt."
                    active={activeForm === forms.CHECK_ONBOARDING}
                    makeActive={() => {
                        setActiveForm(forms.CHECK_ONBOARDING)
                    }}
                >
                    <CheckOnboardingForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Destroy Dealership Onboarding"
                    description="Deletes information related to dealership onboarding. Should only be used when absolutely certain that onboarding failed and the password system was never in use. This is a highly destructive action; please seek developer confirmation before proceeding if possible."
                    active={activeForm === forms.CLEAR_ONBOARDING}
                    makeActive={() => {
                        setActiveForm(forms.CLEAR_ONBOARDING)
                    }}
                >
                    <ClearOnboardingForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
            <Grid item xs={12}>
                <PasswordSystemCard
                    title="Update Dealership Name"
                    description="Allows an authorized user to change the name of a Dealership."
                    active={activeForm === forms.UPDATE_DEALERSHIP_NAME}
                    makeActive={() => {
                        setActiveForm(forms.UPDATE_DEALERSHIP_NAME)
                    }}
                >
                    <UpdateDealershipNameForm
                        getToken={getToken}
                        onCancel={() => {
                            setActiveForm('')
                        }}
                    />
                </PasswordSystemCard>
            </Grid>
        </Grid>
    )
}

function PasswordSystemCard({
    title,
    description,
    active,
    makeActive,
    children
}) {
    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{description}</Typography>
                    </Grid>
                    {!active ? (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    makeActive()
                                }}
                            >
                                Open
                            </Button>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            {children}
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}

PasswordSystemCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    makeActive: PropTypes.func.isRequired,
    children: PropTypes.element
}

function NewAdminForm({ getToken, onCancel }) {
    // Inputs.
    const [cid, setCID] = useState('')
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [initials, setInitials] = useState('')
    // Results.
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: newAdmin, isLoading: newAdminIsLoading } = useMutation(
        api.nps.newAdmin,
        {
            onSuccess: (response) => {
                console.log(response?.data?.desktopSQLQuery)
                setResult(
                    <>
                        API-side operation successful, please use the new admin
                        secret button in Frazer to finalize the operation.
                    </>
                )
            },
            onError: (err) => {
                setResult(parseF4Error(err))
            }
        }
    )

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        newAdmin({
            cid,
            email,
            firstName,
            lastName,
            initials,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={newAdminIsLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={cid}
                            onChange={(e) => {
                                setCID(e.target.value)
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="First Name"
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Initials"
                            value={initials}
                            onChange={(e) => {
                                setInitials(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

NewAdminForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

function PromoteToAdminForm({ getToken, onCancel }) {
    // Inputs.
    const [cid, setCID] = useState('')
    const [email, setEmail] = useState('')
    // Results.
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: promoteToAdmin, isLoading: promoteToAdminIsLoading } =
        useMutation(api.nps.promoteToAdmin, {
            onSuccess: () => {
                setResult(<>Successful.</>)
            },
            onError: (err) => {
                setResult(parseF4Error(err))
            }
        })

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        promoteToAdmin({
            cid,
            email,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={promoteToAdminIsLoading}>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={cid}
                            onChange={(e) => {
                                setCID(e.target.value)
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

PromoteToAdminForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

function CheckCacheForm({ getToken, onCancel }) {
    // Inputs.
    const [cid, setCID] = useState('')
    // Results.
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: checkCache, isLoading: checkCacheIsLoading } =
        useMutation(api.nps.checkCache, {
            onSuccess: (response) => {
                setResult(
                    <List dense={true}>
                        <ListItem>
                            <ListItemText
                                primary={`CID: ${response?.data?.cid}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`Name: ${response?.data?.name}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`Address 1: ${response?.data?.address1}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`Address 2: ${response?.data?.address2}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`City: ${response?.data?.city}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`State: ${response?.data?.state}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`Zip: ${response?.data?.zip}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`API Key: ${response?.data?.apiKey}`}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={`API Key Matches Key In Database: ${
                                    response?.data?.apiKeyMatchesDB
                                        ? 'yes'
                                        : 'no'
                                }`}
                            />
                        </ListItem>
                    </List>
                )
            },
            onError: (err) => {
                setResult(parseF4Error(err))
            }
        })

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()

        console.log('debugging...')
        console.log('checkCache({}) output:')
        console.log(endpoints.nps.checkCache({}))

        const token = await getToken()
        checkCache({
            cid,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={checkCacheIsLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={cid}
                            onChange={(e) => {
                                setCID(e.target.value)
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

CheckCacheForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

function ClearCacheForm({ getToken, onCancel }) {
    // Inputs.
    const [cid, setCID] = useState('')
    // Results.
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const { mutateAsync: clearCache, isLoading: clearCacheIsLoading } =
        useMutation(api.nps.clearCache, {
            onSuccess: () => {
                setResult(<>Successful.</>)
            },
            onError: (err) => {
                setResult(parseF4Error(err))
            }
        })

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        clearCache({
            cid,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={clearCacheIsLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={cid}
                            onChange={(e) => {
                                setCID(e.target.value)
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

ClearCacheForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

function CheckOnboardingForm({ getToken, onCancel }) {
    // Inputs.
    const [cid, setCID] = useState('')
    // Results.
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const {
        mutateAsync: checkOnboarding,
        isLoading: checkOnboardingIsLoading
    } = useMutation(api.nps.checkOnboarding, {
        onSuccess: (response) => {
            setResult(
                <List dense={true}>
                    <ListItem>
                        <ListItemText
                            primary={`Dealerships: ${response?.data?.dealerships}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Employees: ${response?.data?.employees}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Audit Events: ${response?.data?.auditEvents}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Legacy Vehicles: ${response?.data?.legacyVehicles}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Pending Photos: ${response?.data?.pendingPhotos}`}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={`Pending Activities: ${response?.data?.pendingActivities}`}
                        />
                    </ListItem>
                </List>
            )
        },
        onError: (err) => {
            setResult(parseF4Error(err))
        }
    })

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        checkOnboarding({
            cid,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={checkOnboardingIsLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={cid}
                            onChange={(e) => {
                                setCID(e.target.value)
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

CheckOnboardingForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

function ClearOnboardingForm({ getToken, onCancel }) {
    // Inputs.
    const [cid, setCID] = useState('')
    const [confirm, setConfirm] = useState('')
    // Results.
    const [result, setResult] = useState('')

    // API interactions
    const api = useAPIClient()
    const {
        mutateAsync: clearOnboarding,
        isLoading: clearOnboardingIsLoading
    } = useMutation(api.nps.clearOnboarding, {
        onSuccess: () => {
            setResult(<>Successful.</>)
        },
        onError: (err) => {
            setResult(parseF4Error(err))
        }
    })

    // Constant for confirmation input.
    const confirmPrompt = 'I understand'

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        clearOnboarding({
            cid,
            confirm: confirm === confirmPrompt,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={clearOnboardingIsLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={cid}
                            onChange={(e) => {
                                setCID(e.target.value)
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label={`WARNING: This is a destructive action. If you are absolutely sure, type "${confirmPrompt}".`}
                            value={confirm}
                            onChange={(e) => {
                                setConfirm(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

ClearOnboardingForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

const UpdateDealershipNameForm = ({ getToken, onCancel }) => {
    // Input State
    const [fields, setFields] = useState({
        cid: '',
        name: ''
    })
    // Results.
    const [result, setResult] = useState('')
    // API interactions
    const api = useAPIClient()
    const {
        mutateAsync: updateDealershipName,
        isLoading: updateDealershipNameIsLoading
    } = useMutation(api.nps.updateDealershipName, {
        onSuccess: () => {
            setResult(<>Successful.</>)
        },
        onError: (err) => {
            setResult(parseF4Error(err))
        }
    })

    // Form submit handler.
    const handleSubmit = async (e) => {
        e.preventDefault()
        const token = await getToken()
        updateDealershipName({
            cid: fields.cid,
            name: fields.name,
            token
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <fieldset disabled={updateDealershipNameIsLoading}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="CID"
                            value={fields.cid}
                            onChange={(e) => {
                                setFields((f) => ({
                                    ...f,
                                    cid: e.target.value
                                }))
                            }}
                            fullWidth
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="New Name of Dealership"
                            value={fields.name}
                            onChange={(e) => {
                                setFields((f) => ({
                                    ...f,
                                    name: e.target.value
                                }))
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                type="button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                    {result && (
                        <Grid item xs={12}>
                            <Typography>{result}</Typography>
                        </Grid>
                    )}
                </Grid>
            </fieldset>
        </form>
    )
}

UpdateDealershipNameForm.propTypes = {
    getToken: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

// parseF4Error attempts to return a user-friendly error message, and falls
// back to the string representation of the full error object if it was an unexpected shape
function parseF4Error(err) {
    return err.error?.kind && err.error?.message
        ? `${err.error.kind}: ${err.error.message}`
        : `An unexpected error occurred: ${JSON.stringify(err)}`
}
