import { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { Redirect, useParams } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import { formatDateTime } from '../shared'
import { BackupDetailContext } from '../contexts/BackupDetail'

export default function BackupsPage() {
    // Get data from context
    const [backupDetail] = useContext(BackupDetailContext)
    let { cid } = useParams()

    // Return to backups page if we don't have a backup in context.
    if (backupDetail === undefined) {
        return <Redirect to={`/dealership/${cid}`} />
    }

    return (
        <>
            <Grid spacing={2} container>
                <Grid item xs={12}>
                    <Typography variant="h5">Backup Detail</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid item xs={12}>
                                <Typography>
                                    <b>{'Errors: '}</b>
                                    {Array.isArray(backupDetail.errors)
                                        ? backupDetail.errors.length
                                        : '0'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid container item xs={12}>
                                {Array.isArray(backupDetail.errors) &&
                                    backupDetail.errors.map((err, i) => {
                                        return (
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                key={`errDetail=${i}`}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography
                                                        color="error"
                                                        style={{
                                                            overflowWrap:
                                                                'break-word'
                                                        }}
                                                    >
                                                        Error Code: {err.code},
                                                        Error Message:{' '}
                                                        {err.message}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                            </Grid>
                            <Grid container item xs={12}>
                                {backupDetail &&
                                    Object.keys(backupDetail).map(
                                        (objKey, i) => {
                                            // We're already handling errors, so ignore that one
                                            if (objKey === 'errors') {
                                                return null
                                            }
                                            // We'll need to handle formatting the date for the time
                                            if (objKey === 'time') {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={`backup-detail-${i}`}
                                                    >
                                                        <Typography>
                                                            <b>{'time: '}</b>
                                                            {formatDateTime(
                                                                backupDetail[
                                                                    objKey
                                                                ]
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            }
                                            // Everything else should be fine to just output as string
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    key={`backup-detail-${i}`}
                                                >
                                                    <Typography>
                                                        <b>{`${objKey}: `}</b>
                                                        {`${backupDetail[objKey]}`}
                                                    </Typography>
                                                </Grid>
                                            )
                                        }
                                    )}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
