import { useEffect, useState } from 'react'
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import TableCompact from '../components/TableCompact'
import { useQuery, useMutation } from 'react-query'
import { useAPIClient } from 'react-toolbox/APIClient'
import {
    formatDate,
    formatArrays,
    formatVersions,
    stringOfCIDsToArray,
    sanitizeStringOfCIDs,
    sanitizeIntegers,
    sanitize,
    states
} from '../shared'
import { useAuth } from '../contexts/Auth'
import PropTypes from 'prop-types'

// Used as a formatter function for table cells that have long strings (like list of states, or CIDs)
const truncateString = (s) => {
    const maxLen = 20
    // Ensure we have a string
    s = s + ''
    // Do we even need to do anything?
    if (s.length < maxLen) {
        // Do nothing, return
        return s
    }
    // Truncate it
    return s.substring(0, maxLen - 1) + '...'
}

// List of Headers for the Publish Forced Updates table
const draftedUpdatesHeaders = [
    { label: 'Versions', sortable: true, sortType: 'string' }, // Versions affected, Ex: "< 5677" or "5675 - 5677"
    { label: 'Type', sortable: true, sortType: 'string' }, // TPS, or SQL (Or both: "TPS, SQL")
    { label: 'CIDs' }, // CIDs affected, if a lot, should be truncated, and a tooltip can show a set amount. For more detailed look, we'd need to click the details for it
    { label: 'States' }, // States that this update should affect
    { label: 'Reason/Description' }, // Internal viewable description or reason for the forced update
    { label: 'Effective Date', sortable: true, sortType: 'string' }, // Starting date that this forced update would be effective
    { label: 'Who Created', sortable: true, sortType: 'string' } // Who created this forced update
]

// List of Headers for the Publish Forced Updates table
const publishedUpdatesHeaders = [
    { label: 'Versions', sortable: true, sortType: 'string' }, // Versions affected, Ex: "< 5677" or "5675 - 5677"
    { label: 'Type', sortable: true, sortType: 'string' }, // TPS, or SQL (Or both: "TPS, SQL")
    { label: 'CIDs' }, // CIDs affected, if a lot, should be truncated, and a tooltip can show a set amount. For more detailed look, we'd need to click the details for it
    { label: 'States' }, // States that this update should affect
    { label: 'Reason/Description' }, // Internal viewable description or reason for the forced update
    { label: 'Effective Date', sortable: true, sortType: 'string' }, // Starting date that this forced update would be effective
    { label: 'Who Published', sortable: true, sortType: 'string' } // Who published this forced update
]

// List of Headers for the Unpublished Forced Updates table
const unpublishedUpdatesHeaders = [
    { label: 'Versions', sortable: true, sortType: 'string' }, // Versions affected, Ex: "< 5677" or "5675 - 5677"
    { label: 'Type', sortable: true, sortType: 'string' }, // TPS, or SQL (Or both: "TPS, SQL")
    { label: 'CIDs' }, // CIDs affected, if a lot, should be truncated, and a tooltip can show a set amount. For more detailed look, we'd need to click the details for it
    { label: 'States' }, // States that this update should affect
    { label: 'Reason/Description' }, // Internal viewable description or reason for the forced update
    { label: 'Unpublished', sortable: true, sortType: 'string' }, // The date that this forced update was unpublished
    { label: 'Who Unpublished', sortable: true, sortType: 'string' } // Who unpublished this forced update
]

const ForcedUpdatePage = () => {
    // Function to get auth service token to make requests to the API, and email of the user that is logged in
    const { getFzAuthSvcToken, loggedInUsername } = useAuth()
    // State to hold the token
    const [token, setToken] = useState('')
    // Create an async function to fetch the token and update our state
    const getAuthToken = async () => {
        // Get the token
        var t = await getFzAuthSvcToken()
        // Update our state
        setToken(t)
    }
    // Get the token only when the component first mounts
    useEffect(() => {
        getAuthToken()
        // I want an empty effect dependency array to simulate a "component did mount" event
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Used to determine if the Add New Update modal is visible
    const [showModal, setShowModal] = useState(false)
    // Drafted Forced Updates in the expected format for the TableCompact component
    const [draftedTableData, setDraftedTableData] = useState([])
    // Published Forced Updates in the expected format for the TableCompact component
    const [publishedTableData, setPublishedTableData] = useState([])
    // Unplublished Updates TableData in the expected format for the TableCompact component
    const [unpublishedTableData, setUnpublishedTableData] = useState([])
    // API Calls
    const api = useAPIClient()
    // Get List of Rules
    const { data: resp, refetch: getRules } = useQuery(
        ['getRules', token],
        () => api.forcedUpdates.listRules({ token }),
        {
            // We don't want to fire this until we have a token
            enabled: !!token
        }
    )
    // Convert data received from API call to respective tabledata
    useEffect(() => {
        // Do we even have data?
        if (!resp?.rules) {
            // No, but this is normal as the API call may not be complete yet, just return here
            return
        }
        // Are we even working with an array?
        if (!Array.isArray(resp.rules)) {
            // No, put a warning in the console as it is an internal tool, and return here
            console.log(
                'We have rules data, but it is in an unexpected format: ',
                resp.rules
            )
            return
        }

        // We're going to be populating 3 different tables depending on the data we received
        // 1. Drafted Rules (a forced update that has been created but not yet been published)
        var dData = []
        // 2. Published Rules (live forced update rules that are currently published and in effect)
        var pData = []
        // 3. Unpublished Rules (forced update rules that have been unpublished)
        var uData = []

        // Iterate through the results and put them in the proper array
        for (var i = 0; i < resp.rules.length; i++) {
            // Focus on our current iteration
            var r = resp.rules[i]
            // Is it even published yet?
            if (r.published === false && r.who_unpublished === '') {
                // Rule is created but not yet published, add to Drafted rules
                dData.push({
                    navpath: `/forced-updates-detail/${r.id}`, // This allows TableCompact to navigate to the detail page when a row is clicked
                    row: [
                        { data: formatVersions(r.min_version, r.max_version) }, // Versions. Examples: '< 5427', '5425 - 5427'
                        { data: r.db_type }, // System Type. Examples: 'TPS', 'SQL', 'TPS, SQL'
                        {
                            data: formatArrays(r.cids),
                            formatter: truncateString
                        }, // List of CIDs (comma separated string)
                        {
                            data: formatArrays(r.states),
                            formatter: truncateString
                        }, // List of States (comma separated string)
                        { data: r.private_description }, // Internal Description or Reason
                        { data: formatDate(r.effective_date) }, // Effective Date of the forced update
                        { data: r.who_created } // Who create the the draft forced update
                    ]
                })
                // We've done what we need to do, skip to next iteration
                continue
            }
            // It's been published, has it also been unpublished?
            if (r.who_unpublished === '') {
                // Not unpublished, assume it is an active published rule, add to Published rules
                pData.push({
                    navpath: `/forced-updates-detail/${r.id}`, // This allows TableCompact to navigate to the detail page when a row is clicked
                    row: [
                        { data: formatVersions(r.min_version, r.max_version) }, // Versions. Examples: '< 5427', '5425 - 5427'
                        { data: r.db_type }, // System Type. Examples: 'TPS', 'SQL', 'TPS, SQL'
                        {
                            data: formatArrays(r.cids),
                            formatter: truncateString
                        }, // List of CIDs (comma separated string)
                        {
                            data: formatArrays(r.states),
                            formatter: truncateString
                        }, // List of States (comma separated string)
                        { data: r.private_description }, // Internal Description or Reason
                        { data: formatDate(r.effective_date) }, // Effective Date of the forced update
                        { data: r.who_published } // Who published the the forced update
                    ]
                })
                // We've done what we need to do, skip to next iteration
                continue
            }
            // Rule is unpublished, add it to Unpublished rules
            uData.push({
                navpath: `/forced-updates-detail/${r.id}`, // This allows TableCompact to navigate to the detail page when a row is clicked
                row: [
                    { data: formatVersions(r.min_version, r.max_version) }, // Versions. Examples: '< 5427', '5425 - 5427'
                    { data: r.db_type }, // System Type. Examples: 'TPS', 'SQL', 'TPS, SQL'
                    { data: formatArrays(r.cids), formatter: truncateString }, // List of CIDs (comma separated string)
                    { data: formatArrays(r.states), formatter: truncateString }, // List of States (comma separated string)
                    { data: r.private_description }, // Internal Description or Reason
                    { data: formatDate(r.modified) }, // Date that the update was unpublished
                    { data: r.who_unpublished } // Who unpublished the the forced update
                ]
            })
        }

        // Update our state with the new values
        setDraftedTableData(dData)
        setPublishedTableData(pData)
        setUnpublishedTableData(uData)
    }, [resp])
    return (
        <>
            <Grid container spacing={3}>
                <Grid container item xs={12}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setShowModal(true)}
                        >
                            Add New Forced Update
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">
                                <b>Drafted Forced Updates</b>
                            </Typography>
                            <TableCompact
                                headers={draftedUpdatesHeaders}
                                tableData={draftedTableData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">
                                <b>Published Forced Updates</b>
                            </Typography>
                            <TableCompact
                                headers={publishedUpdatesHeaders}
                                tableData={publishedTableData}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">
                                <b>Unpublished Forced Updates</b>
                            </Typography>
                            <TableCompact
                                headers={unpublishedUpdatesHeaders}
                                tableData={unpublishedTableData}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <CreateOrEditRuleModal
                open={showModal}
                close={() => setShowModal(false)}
                loggedInUsername={loggedInUsername}
                token={token}
                refetch={getRules}
            />
        </>
    )
}

export default ForcedUpdatePage

// We break out the field logic into its own component to avoid repeating coding when we
// try to edit a drafted rule from the Detail page
export const CreateOrEditRuleModal = ({
    open,
    close,
    loggedInUsername,
    token,
    existingRule,
    refetch
}) => {
    // Allow the ability to show an "Operation Success" once the modal completes it's intended operation,
    // or an error if something went wrong with the operation
    const [modalStatus, setModalStatus] = useState('') // '', 'success', 'error'
    // If an error was encountered when trying to fire CreateRule, we need to display it to the user
    const [errMsg, setErrMsg] = useState('')
    // Adding new forced update field state

    // placeholder to grab date from so we don't need to initialize 3 new dates
    const now = new Date()
    const [fields, setFields] = useState({
        effDate: existingRule
            ? new Date(existingRule.effective_date)
            : new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        minVersion: existingRule ? existingRule.min_version : 0,
        maxVersion: existingRule ? existingRule.max_version : 0,
        systemType: existingRule ? existingRule.db_type : 'BOTH',
        reason: existingRule ? existingRule.private_description : '',
        dealerMessage: existingRule ? existingRule.public_description : '',
        listOfCIDs: existingRule ? existingRule.cids : '',
        listOfStates: existingRule ? existingRule.states : '',
        // Failed validation messages
        maxErr: '',
        reasonErr: '',
        dealerMessageErr: '',
        listOfStatesErr: ''
    })
    // This allows us to translate user input into the expected values to be fed into the
    // useMutation to make the API call to createRule or updateRule
    const [translatedFields, setTranslatedFields] = useState({
        min: 0,
        max: 0,
        cids: [],
        states: []
    })
    // API Calls
    const api = useAPIClient()
    // Create New Rule
    const { mutate: createNewRule, isLoading: createNewRuleIsLoading } =
        useMutation(
            () =>
                api.forcedUpdates.createRule({
                    min_version: translatedFields.min,
                    max_version: translatedFields.max,
                    db_type: fields.systemType,
                    public_description: fields.dealerMessage,
                    private_description: fields.reason,
                    cids: translatedFields.cids,
                    states: translatedFields.states,
                    effective_date: fields.effDate,
                    who_created: loggedInUsername,
                    token
                }),
            {
                onSuccess: () => {
                    // Refetch fresh data after successful operation
                    refetch()
                    // Success, inform user
                    setModalStatus('success')
                },
                onError: (err) => {
                    // Log error with console.log as it is an internal tool
                    console.log('Error: ', err)
                    // If we encountered an error, inform the user
                    setModalStatus('error')
                    if (err?.message) {
                        setErrMsg(err.message)
                    } else {
                        setErrMsg(
                            'An error occurred when trying to create new forced update. Please try again.'
                        )
                    }
                }
            }
        )
    // Handler for our mutation, allows us to perform some validation before firing the API call
    const createNewRuleHandler = () => {
        // Are we ready to fire the request?
        if (
            fields.dealerMessageErr !== '' ||
            fields.reasonErr !== '' ||
            fields.listOfStatesErr !== '' ||
            fields.maxErr !== ''
        ) {
            // Validation has yet to be addressed, just return here and do not execute API call
            return
        }
        // Do we even have input in required fields?
        // Convert max to a number
        let n = Number(fields.maxVersion)
        if (
            fields.dealerMessage === '' ||
            fields.reason === '' ||
            n === 0 ||
            isNaN(n)
        ) {
            // Update our field message to the user
            setFields((f) => ({
                ...f,
                dealerMessageErr:
                    fields.dealerMessage === ''
                        ? 'This is a required field.'
                        : '',
                reasonErr:
                    fields.reason === '' ? 'This is a required field.' : '',
                maxErr: n === 0 || isNaN(n) ? 'Valid entry required.' : ''
            }))
            // Return here and do not execute api call
            return
        }
        // Fire the request
        createNewRule()
    }
    // Update / Modify Rule
    const { mutate: updateRule, isLoading: updateRuleIsLoading } = useMutation(
        () =>
            api.forcedUpdates.updateRule({
                id: existingRule.id,
                min_version: translatedFields.min,
                max_version: translatedFields.max,
                db_type: fields.systemType,
                public_description: fields.dealerMessage,
                private_description: fields.reason,
                cids: translatedFields.cids,
                states: translatedFields.states,
                effective_date: fields.effDate,
                who_created: loggedInUsername,
                token
            }),
        {
            onSuccess: () => {
                // Refetch fresh data after successful operation
                refetch()
                // Success, inform user
                setModalStatus('success')
            },
            onError: (err) => {
                // Log error with console.log as it is an internal tool
                console.log('Error: ', err)
                // If we encountered an error, inform the user
                setModalStatus('error')
                if (err?.message) {
                    setErrMsg(err.message)
                } else {
                    setErrMsg(
                        'An error occurred when trying to update forced update. Please try again.'
                    )
                }
            }
        }
    )
    // Handler for updating rule, allows us to do some validation before firing API call
    const updateRuleHandler = async () => {
        // Validation
        // Parse and validate states when the user is done using this field
        var [stateArray, invalid] = parseStates(fields.listOfStates)
        // Did we encounter an issue?
        if (invalid) {
            setFields((f) => ({
                ...f,
                listOfStatesErr:
                    'Invalid State detected, states should be abbreviated and separated by commas. Example: "NY, TX"'
            }))
            return
        }
        // Translate fields
        // State setters are async, without this 'await' here, the updateRule()
        // was firing before the state was actually set, causing some fields to
        // be blank in the request.
        await setTranslatedFields((f) => ({
            ...f,
            min: Number(fields.minVersion),
            max: Number(fields.maxVersion),
            cids: stringOfCIDsToArray(fields.listOfCIDs),
            states: stateArray
        }))
        // Fire API call
        updateRule()
    }
    return (
        <Dialog
            open={open}
            onClose={() => {
                // This can fire if user clicks outside of modal, and not the "Cancel" button
                // Clear any error message and close modal
                setErrMsg('')
                close()
            }}
            fullWidth={true}
            maxWidth={modalStatus === '' ? 'md' : 'xs'}
        >
            <DialogTitle>
                {existingRule ? 'Modify' : 'Add New'} Forced Update
            </DialogTitle>
            <DialogContent>
                {modalStatus === '' && (
                    <Grid container>
                        <Grid container item xs={12}>
                            <Typography>Effective Date</Typography>
                            <DatePicker
                                customInput={
                                    <TextField helperText="The date that this forced update will take effect." />
                                }
                                selected={fields.effDate}
                                onChange={(date) => {
                                    // we only care about mmddyyyy
                                    date = new Date(
                                        date.getFullYear(),
                                        date.getMonth(),
                                        date.getDate()
                                    )
                                    // Update our field with the new date from the Datepicker
                                    setFields((f) => ({
                                        ...f,
                                        effDate: date
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            item
                            xs={12}
                            style={{ paddingTop: 8 }}
                        >
                            <Grid item xs={4}>
                                <Typography>Minimum Version</Typography>
                                <TextField
                                    helperText="Leave this defaulted to 0 if you want to include all versions prior to the maximum."
                                    value={fields.minVersion}
                                    onChange={(e) => {
                                        // Update our field with the new value
                                        setFields((f) => ({
                                            ...f,
                                            minVersion: sanitizeIntegers(
                                                e.target.value
                                            )
                                        }))
                                    }}
                                    onBlur={() => {
                                        // This fires when the user is done with the field
                                        // Convert to an int, and Update our state
                                        setTranslatedFields((f) => ({
                                            ...f,
                                            min: Number(fields.minVersion)
                                        }))
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Maximum Version</Typography>
                                <TextField
                                    helperText={
                                        fields.maxErr
                                            ? fields.maxErr
                                            : 'Any Dealers on a later version than the max will not be affected.'
                                    }
                                    error={fields.maxErr !== ''}
                                    value={fields.maxVersion}
                                    onChange={(e) => {
                                        // Update our field with the new value, and clear any validation error
                                        setFields((f) => ({
                                            ...f,
                                            maxErr: '',
                                            maxVersion: sanitizeIntegers(
                                                e.target.value
                                            )
                                        }))
                                    }}
                                    onBlur={() => {
                                        // This fires when the user is done with the field
                                        // Convert it to a number
                                        let n = Number(fields.maxVersion)
                                        // Ensure we have something
                                        if (n === 0 || isNaN(n)) {
                                            // Invalid input, warn user
                                            setFields((f) => ({
                                                ...f,
                                                maxErr: 'Valid entry is required.'
                                            }))
                                        }
                                        // Update translated state
                                        setTranslatedFields((f) => ({
                                            ...f,
                                            max: n
                                        }))
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} style={{ paddingTop: 8 }}>
                            <Grid item xs={12}>
                                <Typography>System Type</Typography>
                            </Grid>
                            <FormControl>
                                <RadioGroup
                                    row
                                    value={fields.systemType}
                                    onChange={(e) => {
                                        // Update our field with the new value
                                        setFields((f) => ({
                                            ...f,
                                            systemType: sanitize(e.target.value)
                                        }))
                                    }}
                                >
                                    <FormControlLabel
                                        value="BOTH"
                                        control={<Radio />}
                                        label="Any"
                                    />
                                    <FormControlLabel
                                        value="TPS"
                                        control={<Radio />}
                                        label="TPS Systems Only"
                                    />
                                    <FormControlLabel
                                        value="SQL"
                                        control={<Radio />}
                                        label="SQL Systems Only"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={12} style={{ paddingTop: 8 }}>
                            <Typography>Internal Message</Typography>
                            <TextField
                                multiline
                                fullWidth
                                error={fields.reasonErr !== '' ? true : false}
                                placeholder="EVERYTHING IS ON FIRE, THIS IS A HUGE PROBLEM"
                                helperText={
                                    fields.reasonErr !== ''
                                        ? fields.reasonErr
                                        : "What's the reason for this forced update? Only viewable by Frazer employees."
                                }
                                value={fields.reason}
                                onChange={(e) => {
                                    // Update our field with the new value and clear validation message
                                    setFields((f) => ({
                                        ...f,
                                        reason: sanitize(e.target.value),
                                        reasonErr: ''
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{ paddingTop: 8 }}>
                            <Typography>Update Message</Typography>
                            <TextField
                                multiline
                                fullWidth
                                error={
                                    fields.dealerMessageErr !== ''
                                        ? true
                                        : false
                                }
                                placeholder="There is a small problem with Frazer and it needs an update! The version you are currently using needs to be updated immediately."
                                helperText={
                                    fields.dealerMessageErr !== ''
                                        ? fields.dealerMessageErr
                                        : 'This is the message displayed to the Dealer when they are forced to update.'
                                }
                                value={fields.dealerMessage}
                                onChange={(e) => {
                                    // Update our field with the new value and clear validation message
                                    setFields((f) => ({
                                        ...f,
                                        dealerMessage: sanitize(e.target.value),
                                        dealerMessageErr: ''
                                    }))
                                }}
                                onBlur={() => {
                                    // Validate the field when the user is done using it
                                    if (fields.dealerMessage === '') {
                                        // Field cannot be empty
                                        setFields((f) => ({
                                            ...f,
                                            dealerMessageErr:
                                                'Public Description cannot be empty.'
                                        }))
                                    }
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{ paddingTop: 8 }}>
                            <Typography>List of CIDs (Optional)</Typography>
                            <TextField
                                multiline
                                fullWidth
                                placeholder="2,18292,1337"
                                helperText="This field is copy/pasteable, accepts a comma separated list of CIDs this update should target."
                                value={fields.listOfCIDs}
                                onChange={(e) => {
                                    // Update our field with the new value
                                    setFields((f) => ({
                                        ...f,
                                        listOfCIDs: sanitizeStringOfCIDs(
                                            e.target.value
                                        )
                                    }))
                                }}
                                onBlur={() => {
                                    // This event fires when this field is no longer in focus (such as tab to next field, or clicking on next field)
                                    // As we could have potentially thousands of CIDs to parse, we don't want to do that while the user is typing them in
                                    // (Ideally they would just copy/paste however that may not be realistic), so we do this operation when they leave the field
                                    setTranslatedFields((prev) => ({
                                        ...prev,
                                        cids: stringOfCIDsToArray(
                                            fields.listOfCIDs
                                        )
                                    }))
                                }}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{ paddingTop: 8 }}>
                            <Typography>List of States (Optional)</Typography>
                            <TextField
                                multiline
                                fullWidth
                                error={
                                    fields.listOfStatesErr !== '' ? true : false
                                }
                                placeholder="NY,GA,TX,IA,FL"
                                helperText={
                                    fields.listOfStatesErr !== ''
                                        ? fields.listOfStatesErr
                                        : 'This field is copy/pastable, accepts a comma separated list of abbreviated States this update should target.'
                                }
                                value={fields.listOfStates}
                                onChange={(e) => {
                                    // Update our field with the new value and clear validation message
                                    setFields((f) => ({
                                        ...f,
                                        listOfStates: sanitize(e.target.value),
                                        listOfStatesErr: ''
                                    }))
                                }}
                                onBlur={() => {
                                    // Parse and validate states when the user is done using this field
                                    var [stateArray, invalid] = parseStates(
                                        fields.listOfStates
                                    )
                                    // Did we encounter an issue?
                                    if (invalid) {
                                        setFields((f) => ({
                                            ...f,
                                            listOfStatesErr:
                                                'Invalid State detected, states should be abbreviated and separated by commas. Example: "NY, TX"'
                                        }))
                                        return
                                    }
                                    // Entry was valid, let's update our request state
                                    setTranslatedFields((f) => ({
                                        ...f,
                                        states: stateArray
                                    }))
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {modalStatus === 'error' && (
                    <Grid container>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid item>
                                <Typography variant="h6">
                                    Something went wrong...
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid item>
                                <ErrorOutlineIcon
                                    style={{ height: 128, width: 128 }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid item>
                                <Typography variant="h6">{errMsg}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {modalStatus === 'success' && (
                    <Grid container>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid item>
                                <Typography variant="h6">
                                    Operation Successful
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" item xs={12}>
                            <Grid item>
                                <CheckCircleOutlineIcon
                                    style={{ height: 128, width: 128 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        // Clear any error message and close modal
                        setErrMsg('')
                        close()
                    }}
                >
                    {modalStatus === '' ? 'Cancel' : 'Close'}
                </Button>
                {modalStatus === '' && (
                    <Button
                        disabled={createNewRuleIsLoading}
                        onClick={() => {
                            if (existingRule) {
                                updateRuleHandler()
                            } else {
                                createNewRuleHandler()
                            }
                        }}
                    >
                        {existingRule
                            ? updateRuleIsLoading
                                ? 'Modifying Update'
                                : 'Modify Update'
                            : createNewRuleIsLoading
                            ? 'Creating Forced Update...'
                            : 'Create Forced Update'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

CreateOrEditRuleModal.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
    loggedInUsername: PropTypes.string,
    token: PropTypes.string,
    refetch: PropTypes.func,
    existingRule: PropTypes.shape({
        id: PropTypes.number,
        min_version: PropTypes.number,
        max_version: PropTypes.number,
        db_type: PropTypes.string,
        public_description: PropTypes.string,
        private_description: PropTypes.string,
        cids: PropTypes.arrayOf(PropTypes.number),
        states: PropTypes.arrayOf(PropTypes.string),
        effective_date: PropTypes.string
    })
}

/**
 * parseStates converts a string into an array of states
 * @param {string} s The string to parse into an array
 * @returns {string[]} Array of states
 * @returns {boolean} Denotes if any invalid states were detected
 */
const parseStates = (s) => {
    // Ensure we have a string
    s = s + ''
    // Do we have any input at all?
    if (s === '') {
        // No input, which is acceptable, just return an empty array
        return [[], false]
    }
    // Remove whitespace form string
    s = s.replace(/\s/g, '')
    // Make it all Uppercase
    s = s.toUpperCase()
    // Parse string of States into array
    var arr = s.split(',')
    // Verify states are valid
    var invalid = false
    for (var i = 0; i < arr.length; i++) {
        // Do we have a valid state?
        // Linter doesn't like this approach
        // eslint-disable-next-line no-loop-func
        var found = states.find((state) => state === arr[i])
        if (!found) {
            // No, we have an invalid state, set our flag and break out of loop
            invalid = true
            break
        }
    }
    // Return parsed array and validation result
    return [arr, invalid]
}
